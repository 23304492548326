import { Toast } from "react-bootstrap";
import common from "../utilities/common";

/** 
 * return toast with filter conditon
 * @param {object} message object that store filter condition, number of data , condition description
 * @param {string} message.condition filter condition
 * @param {string} message.dataCount data's number
 * @param {string} message.description descriptioon of condition
 */
function MessageCard({ message }) {
    if (message == null) {
        return "";
    }

    const bg = "success";
    const text = "light";

    const colorCode = common.colorCode.no_BC9C64;
    //"#74B5BC";
    const colorCodeLight = "light";
    const classNameTextWhite = "text-white";

    let toastHeadStrong = `您的搜尋結果如下`;
    let toastHeadSmall = ``;

    return (
        <Toast style={{ width: "100%", backgroundColor: colorCode, color: colorCodeLight }}>
            <Toast.Header closeButton={false} style={{ backgroundColor: colorCode, color: colorCodeLight }} className={classNameTextWhite}>
                <strong className="me-auto">{toastHeadStrong}</strong>
                <small className="text-muted">{toastHeadSmall}</small>
            </Toast.Header>
            <Toast.Body bg={bg} show={`true`} className={classNameTextWhite}>
                {`${message.condition}`}
                {`共計`}
                {<strong>{`${message.dataCount}`}</strong>}
                {`筆`}
                {`。`}
            </Toast.Body>
        </Toast>
        // <Card text={text} style={{ backgroundColor: colorCode }}>
        //     <Card.Body>
        //         {message}
        //     </Card.Body>
        // </Card>
    )
}

export default MessageCard;