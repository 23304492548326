import logoApplicationLogin from "../../assets/img/logoApplicationLogin.jpg";

// js
import { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";
import { toast } from "react-toastify";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";
import axios from "axios";

function ApplicationRegisterPage() {
  //#region arrange string var
  const descriptionColor = `#5A493F`;
  const buttonColor = "#E75711";
  //#endregion

  // hook state
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [registering, setRegistering] = useState(false);
  const [registerOk, setRegisterOk] = useState(false);

  const isPasswordMatchRule = (password) => {
    const matchResult = password.match(
      /^(?=.*[0-9])(?=.*[a-zA-Z])(?=[a-zA-Z0-9]{8})([a-zA-Z0-9]+)$/g
    );

    if (!matchResult) {
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;

      if (e.target.id === "account") {
        setAccount(inputValue);
      } else if (e.target.id === "password") {
        setPassword(inputValue);
      } else if (e.target.id === "passwordCheck") {
        setPasswordCheck(inputValue);
      }
    }
  };

  const handleLogin = async (e) => {
    if (e.keyCode !== 13 && e.type !== "click") {
      return;
    }

    if (password !== passwordCheck) {
      common.toastEmmiter("密碼必須相同");
      return;
    }
    if (!isPasswordMatchRule(password) || !isPasswordMatchRule(passwordCheck)) {
      common.toastEmmiter("請使用英文與數字組合8位數以上之密碼");
      return;
    }

    setRegistering(true);

    const url = process.env.REACT_APP_Application_Url;
    const registerRoute = process.env.REACT_APP_Application_Route_Register;

    const formData = new FormData();
    formData.append("email", account);
    formData.append("password", password);

    const options = {
      method: "POST",
      url: `${url}/${registerRoute}`,
      body: formData,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    };

    try {
      const response = await axios.post(
        options.url,
        options.body,
        options.config
      );

      if (response && response.status === 200) {
        const emailRoute =
          process.env.REACT_APP_Application_Route_Send_Verify_Email;

        const formDataSendVerifyEmail = new FormData();
        formDataSendVerifyEmail.append("email", account);

        const sendEmailResponse = await axios.post(
          `${url}/${emailRoute}`,
          formDataSendVerifyEmail,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (sendEmailResponse && sendEmailResponse.status === 200) {
          common.toastEmmiter("註冊成功，請前往信箱收信");
          setRegisterOk(true);
        }
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data.errors &&
        error.response.data.errors.email.length > 0
      ) {
        const message = error.response.data.errors.email[0];
        toast.error(message, { position: "top-center" });
      } else {
        console.error(error);
        alert(error.message);
      }
    } finally {
      setRegistering(false);
    }
  };

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        {registerOk || (
          <Card className={"loginStyle text-center"}>
            <Card.Body>
              <Row>
                <Col md={2}></Col>
                <Col md={10} style={{ textAlign: "left" }}>
                  <span style={{ color: common.colorCode.no_E75711 }}>
                    建議使用公務信箱帳號申請 如：.gov .edu
                  </span>
                </Col>
              </Row>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{
                    color: descriptionColor,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <span className="material-icons-outlined">email</span>
                  &nbsp;&nbsp;
                  {"信箱"}
                </InputGroup.Text>
                <Form.Control
                  onChange={handleChange}
                  id={"account"}
                  placeholder="請輸入信箱"
                  aria-describedby="email"
                  type="email"
                  pattern=".+@(gov|edu)"
                  style={{ backgroundColor: "#DFDCDA", border: "none" }}
                />
              </InputGroup>
              <Row>
                <Col md={2}> </Col>
                <Col md={10} style={{ textAlign: "left" }}>
                  <span style={{ color: common.colorCode.no_E75711 }}>
                    請使用英文與數字組合8位數以上之密碼
                  </span>
                </Col>
              </Row>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{
                    color: descriptionColor,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <span className="material-icons-outlined">lock</span>
                  &nbsp;&nbsp;
                  {"密碼"}
                </InputGroup.Text>
                <Form.Control
                  onChange={handleChange}
                  id={"password"}
                  placeholder="請輸入密碼"
                  aria-describedby="password"
                  style={{ backgroundColor: "#DFDCDA", border: "none" }}
                  type={`password`}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text
                  style={{
                    color: "transparent",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <span className="material-icons-outlined">lock</span>
                  &nbsp;&nbsp;
                  {"密碼"}
                </InputGroup.Text>
                <Form.Control
                  onChange={handleChange}
                  onKeyUp={handleLogin}
                  id={"passwordCheck"}
                  placeholder="請再輸入一次密碼"
                  aria-describedby="password"
                  style={{ backgroundColor: "#DFDCDA", border: "none" }}
                  type={`password`}
                />
              </InputGroup>
              <br></br>
              <div style={{ textAlign: "right" }}>
                <Button
                  onClick={handleLogin}
                  style={{
                    width: "30%",
                    borderRadius: "0.8rem",
                    backgroundColor: buttonColor,
                    border: "none",
                  }}
                  disabled={registering}
                >
                  {registering && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {"註冊"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
        {registerOk && (
          <Card
            className={"loginStyle text-center"}
            style={{
              backgroundColor: common.colorCode.no_E6DED3,
              width: "100%",
              padding: "5rem",
              borderRadius: "0.5rem",
            }}
          >
            <Card.Body
              style={{ color: common.colorCode.no_E75711, fontSize: "2rem" }}
            >
              <Card.Text>請至註冊之信箱收取驗證信</Card.Text>
              <Card.Text>驗證通過後才算完成註冊</Card.Text>
            </Card.Body>
          </Card>
        )}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationRegisterPage;
