import logoLogin from "../assets/img/logoLogin.jpg";
import SSLLogo from "../assets/img/SSLSeal.gif";

// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
} from "react-bootstrap";
import common from "../utilities/common";
import apiCaller from "../utilities/apiCaller";
import cookieManager from "../utilities/cookiesManager";

// custom css
import "../assets/css/backgroundLogin.css";
import "../assets/sass/loginStyle.scss";

/**
 * Login page
 * @returns {JSX} Login page
 */
function Login({ setLoginData, setIsAuthorized }) {
  //#region arrange string var
  const description1 = `’aveoveoyʉ,`;
  const description2 = `歡迎使用`;
  const description3 = `「財團法人原住民族語言研究發展基金會原住民族語言人才資料庫」`;
  const description4 = `本資料庫依據原住民族語言發展法第13條第2項設立，目前僅開放中央及地方各機關單位、各族語言推動組織使用。`;
  const description5 = `本資料庫帳號密碼請洽本基金會申請，並請務必遵守個資法及相關規範妥善保管。`;
  const descriptionColor = `#5A493F`;
  const descriptionColor2 = `#E75711`;

  const contact1 = `若有其他問題請洽本基金會研究發展組業務承辦人：`;
  const contact2 = `徐小姐`;
  const contactBackgroundColor = `#F6F6F6`;
  const contactColor = `#7D8E96`;
  const phone = `02-23418508#602`;
  const or = `或`;
  const email = `akiwhsu@ilrdf.org.tw`;

  const copyRight1 = `財團法人原住民族語言研究發展基金會`;
  const copyRight2 = `Indigenous Languages Research and Development Foundation`;
  const copyRight3 = `10093台北市中正區羅斯福路一段63號 電話：02-23418508#602`;
  const copyRight4 = `財團法人原住民族語言研究發展基金會 ©版權所有`;
  const copyRight5 = `Copyright © ${new Date().getFullYear()} Indigenous Languages Research and Development Foundation`;

  const warningMessageColor = `#A3432D`;
  const buttonColor = "#E75711";
  const footerColor = "#7D8E96";
  //#endregion

  let history = useHistory();

  // hook state
  const [warningMessage, setWarningMessage] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!setLoginData) {
      window.location.reload();
    }
  });

  // if cookie exists, then set location to huamn resource page
  if (cookieManager.getCookieValue("humanresource")) {
    window.location.href = `${window.location.origin}/`;
  }

  const handleLogin = async (e) => {
    if (account == "" || password == "") {
      common.toastEmmiter("請輸入正確的帳號與密碼");
      setWarningMessage("請輸入正確的帳號與密碼");
      return;
    }
    const keycode = e.keyCode ? e.keyCode : e.which;
    const type = e.type;
    if (keycode === 13 || type === "click") {
      common.loader.show();

      let isActivated = true;

      try {
        // prepare bodyContent
        const formData = new FormData();
        formData.append("account", account);
        formData.append("password", password);

        // [PRD] production environment
        const options = {
          method: "POST",
          url: `https://ihr-api.ilrdf.org.tw/api/v1/login`,
          body: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        };

        // **********************************************************************
        // [DEV] json server for testing
        // const options = {
        //     method: "GET",
        //     url: `https://my-json-server.typicode.com/s780609/JsonServer/hrlogin?account=${account}&password=${password}`,
        // }
        // **********************************************************************

        const data = await apiCaller(options, true);

        if (data.data && data.data.auth_token) {
          // set cookie , this is should be server side work
          // set itself temporarily, waiting for api
          await cookieManager.setCookie(
            "humanresource",
            data.data.auth_token,
            1800
          );

          // mapping login data to loginData object, and set to sessionStorage named loginData
          const loginData = {
            user_id: data.data.user_id,
            display_name: data.data.display_name,
            account: data.data.account,
            name: data.data.name,
            email: data.data.email,
          };

          sessionStorage.setItem("loginData", JSON.stringify(loginData));

          setLoginData(data.data);
          setIsAuthorized(true);

          if (data.data.activated && data.data.activated.toString() === "0") {
            isActivated = false;
          }
        } else {
          // display warning message
          common.toastEmmiter("帳號或密碼錯誤，請重新確認");
          setWarningMessage("帳號或密碼錯誤，請重新確認");
        }
      } catch (e) {
        console.error(e);
        alert(e.message);
      } finally {
        common.loader.close();

        if (!isActivated) {
          history.push("#/changepassword");
        }
      }
    }
  };

  function handleChange(e) {
    switch (e.target.id) {
      case "account":
        setAccount(e.target.value);
        return;
      case "password":
        setPassword(e.target.value);
        return;
      default:
        return;
    }
  }

  function openUrl(e) {
    const url =
      "https://publicca.hinet.net/SSLQueryCert/SSLQueryCert.jsp?Domain_name=ihr.ilrdf.org.tw";
    window.open(url);
  }

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img src={`${logoLogin}`} width={`100%`} height={`auto`} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card
          style={{
            color: descriptionColor,
            backgroundColor: "#E6DED3",
            border: "none",
          }}
        >
          <Card.Body>
            <Card.Text className={"text-center"}>{description1}</Card.Text>
            <Card.Text className={"text-center"}>
              {description2}
              <span style={{ color: descriptionColor2 }}>{description3}</span>
            </Card.Text>
            <Card.Text className={"text-center"}>{description4}</Card.Text>
            <Card.Text className={"text-center"}>{description5}</Card.Text>
            <Card.Text
              style={{
                backgroundColor: contactBackgroundColor,
                border: "5px solid white",
              }}
              className={"text-center"}
            >
              <h6 style={{ marginTop: "0.625em" }}>{contact1}</h6>
              <h6 style={{ color: contactColor, marginBottom: "0.625em" }}>
                {contact2}
                {` `}
                <span className="material-icons">call</span>
                {` `}
                {phone}
                {` `}
                {or}
                {` `}
                <span className="material-icons">mail</span>
                {` `}
                {email}
              </h6>
            </Card.Text>
          </Card.Body>
        </Card>
        <br></br>
        <Card className={"loginStyle text-center"}>
          <Card.Body>
            <div style={{ textAlign: "right" }}>
              <a
                style={{
                  color: common.colorCode.no_E75711,
                  textdecoration: "none",
                  fontSize: "0.9rem",
                }}
                href="/forgetpassword"
              >
                忘記密碼
              </a>
            </div>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons">person</span>
                &nbsp;&nbsp;
                {"帳號"}
              </InputGroup.Text>
              <FormControl
                onChange={handleChange}
                id={"account"}
                placeholder="請輸入帳號"
                aria-describedby="username"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons">lock</span>
                &nbsp;&nbsp;
                {"密碼"}
              </InputGroup.Text>
              <FormControl
                onChange={handleChange}
                onKeyUp={handleLogin}
                id={"password"}
                placeholder="請輸入密碼"
                aria-describedby="password"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
                type={`password`}
              />
            </InputGroup>
          </Card.Body>
          <p style={{ color: warningMessageColor }}>{warningMessage}</p>
          <Button
            onClick={handleLogin}
            style={{
              borderRadius: "25px",
              backgroundColor: buttonColor,
              border: "none",
            }}
          >
            {"登入"}
          </Button>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Card
          className={"text-center"}
          style={{
            color: footerColor,
            fontSize: "0.8em",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <Card.Body>
            <Card.Text>
              <div>{copyRight1}</div>
              <div>{copyRight2}</div>
              <div>{copyRight3}</div>
            </Card.Text>
            <Card.Text>
              <div>{copyRight4}</div>
              <div>{copyRight5}</div>
            </Card.Text>
            <Card.Text>
              <a
                //href="javascript:location.href='https://publicca.hinet.net/SSLQueryCert/SSLQueryCert.jsp?Domain_name=ihr.ilrdf.org.tw'"
                href="#"
                onClick={openUrl}
              >
                <img src={SSLLogo} width="50" height="70" />
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
        <br></br>
      </Container>
    </>
  );
}

export default Login;
