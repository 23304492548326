// img
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";

/**
 * instructions
 * @returns {JSX} instructions components
 */
function InstructionsCard() {
  return (
    <>
      {/* <div style={{ height: "100%", background: "white" }}> */}
      <Card style={{ padding: "0 2rem 0 2rem" }}>
        <Container style={{ marginTop: "2em" }}>
          <Row>
            <Col md={8}>
              <h2>
                <strong>資料庫使用說明</strong>
              </h2>
            </Col>
            <Col md={4} className="text-end">
              <a
                href="https://ihr.ilrdf.org.tw/download/instructions.pdf"
                download="原住民族語言人才資料庫-使用說明手冊"
              >
                <Button variant="primary">使用說明手冊下載</Button>{" "}
              </a>
            </Col>
          </Row>
          <Row>
            <Table
              width="100%"
              style={{ fontSize: "0.5em" }}
              className="table-borderless"
            >
              <tbody>
                <tr>
                  <td width="40">
                    <h6>
                      <strong>壹、</strong>
                    </h6>
                  </td>
                  <td colSpan="4">
                    <h6>
                      本資料庫係依據原住民族語言發展法（下稱原語法）第13條第2項設立，並由原住民族語言研究發展基金會（原語會）建置之原住民族語言人才資料，及族語振興而設立，主要供中央及地方各機關單位、語言推動組織查詢，俾利族語工作之推動。本資料庫之資料嚴禁任意公開或外流，使用及保存請謹守個資法之規定。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>
                      <strong>貳、</strong>
                    </h6>
                  </td>
                  <td colSpan="4">
                    <h6>本資料庫包含以下人才類別：</h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>一、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>測驗分類：</strong>
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>﹙一﹚測驗優級類：</strong>
                      係指通過國家族語優級測驗之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>﹙二﹚族語認證高級─新制 (103年以後)：</strong>
                      係指通過103年以後國家族語測驗之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>（三）測驗中高級類：</strong>
                      係指通過國家族語中高級測驗之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>（四）族語認證同高級─舊制(102年以前)：</strong>
                      係指通過102年以前國家族語測驗之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>二、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>教學類：</strong>
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>﹙一﹚合格師資：</strong>
                      係指通過族語認證高級（含102年前舊制）及通過族語振興人員研習，符合族語老師資格之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>﹙二﹚族語老師：</strong>
                      係指通過族語認證高級（含102年前舊制）及通過族語振興人員研習，並有教學經驗之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong></strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>（三）專職族語老師：</strong>
                      係指107年、108年受聘擔任專職族語老師之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>三、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>翻譯類：</strong>
                      係指曾經參與族語讀本編譯或曾從事族語翻譯、出版及競賽之個人。{" "}
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>四、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>創詞類：</strong>
                      係指曾經參與推薦新詞創詞小組或語推組織新創詞小組之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>五、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>配音類：</strong>
                      係指原民會配音研習班結業或曾從事族語及非族語影片配音之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td width="40">
                    <h6>
                      <strong>六、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>法院通譯類：</strong>
                      係指曾在臺灣各地方法院進行通譯之個人。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td>
                    <h6>
                      <strong>七、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>語推類：</strong>
                      係指原語法第5條所稱之「原住民族語言推廣人員」，於各鄉、鎮、市、區公所進行語言使用之推廣、推動族語學習、保存族語語料及語言振興工作。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>
                      <strong>參、</strong>
                    </h6>
                  </td>
                  <td colSpan="2">
                    <h6>各人才資料包含以下內容：</h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td>
                    <h6>
                      <strong>一、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      性別以姓名標示顏色區分，
                      <strong>
                        <span style={{ color: "rgb(113, 131, 107)" }}>
                          男性為墨綠色
                        </span>
                      </strong>
                      ，
                      <strong>
                        <span style={{ color: "rgb(188, 156, 100)" }}>
                          女性為暗金色。
                        </span>
                      </strong>
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td>
                    <h6>
                      <strong>二、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>出生年</strong>則以英文字母
                      <strong>b.加西元年</strong>代表。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td>
                    <h6>
                      <strong>三、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      各項目查詢結果若標示數字，代表參與或執行該項族語工作之民國年；族語支援老師標記其服務學校（如僅標記「是」者，則為有族語支援老師經驗但無註記服務學校者）；翻譯人才則另外註記完成之翻譯。備註欄則是其他有關族語工作經驗之補充。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td width="40"></td>
                  <td>
                    <h6>
                      <strong>四、</strong>
                    </h6>
                  </td>
                  <td>
                    <h6>
                      <strong>數字</strong>代表參與或執行該項族語工作之
                      <strong>民國</strong>
                      年；族語支援老師標記服務學校（如無服務學校卻為支援老師者，則以「是」標記）；翻譯人才則另外註記完成之翻譯。備註欄提供研習講師專長的課程。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>
                      <strong>肆、</strong>
                    </h6>
                  </td>
                  <td colSpan="2">
                    <h6>
                      本資料庫具備基本搜尋：
                      <strong>「語別」、「方言別」、「關鍵字」</strong>
                      及進階搜尋地區條件的：
                      <strong>「地區」、「城市」、「鄉鎮市區」</strong>
                      及進階搜尋專才條件的：
                      <strong>「特殊專長或技能」、「方言別」、「年份」</strong>
                      ，可依個人需求進行基礎搜尋或搭配進階搜尋（例如指定地區條件及專才條件）搜尋。關鍵字搜尋設定為
                      <strong>「漢名」、「族名」、「地址」、「備註」</strong>
                      等內容皆可搜尋。
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h6>
                      <strong>伍、</strong>
                    </h6>
                  </td>
                  <td colSpan="2">
                    <h6>
                      若對於本資料庫有相關問題，請洽本基金會研究發展組業務承辦人：徐小姐（
                      <span className="material-icons">call</span>{" "}
                      <a href="tel:02-2341-7673">02-23418508#602</a>或
                      <span className="material-icons">mail</span>{" "}
                      <a href="mailto:akiwhsu@ilrdf.org.tw">
                        akiwhsu@ilrdf.org.tw
                      </a>
                      ）。
                    </h6>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Container>
      </Card>
      {/* </div> */}
    </>
  );
}

export default InstructionsCard;
