import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Row, Col, Form, Container } from "react-bootstrap";

import downloadPageLogo from "../../assets/img/downloadPageLogo.png";
import common from "../../utilities/common";
import axios from "axios";
import cookieManager from "../../utilities/cookiesManager";

// css
import "../../assets/css/downloadPageTable.css";

function ApplicationDownloadPage(props) {
  const dateNow = new Date();
  const yearROC = dateNow.getFullYear() - 1911;
  const month = dateNow.getMonth()+1;
  const day = dateNow.getDate();

  let history = useHistory();

  let id;

  if (props.match && props.match.params) {
    id = props.match.params.id;
  }

  // hook state
  const [data, setData] = useState({});

  const [languagesOption, setLanguagesOption] = useState([
    {
      lid: 1,
      lch: "阿美族",
    },
    {
      lid: 2,
      lch: "泰雅族",
    },
    {
      lid: 3,
      lch: "賽夏族",
    },
    {
      lid: 4,
      lch: "邵族",
    },
    {
      lid: 5,
      lch: "賽德克族",
    },
    {
      lid: 6,
      lch: "布農族",
    },
    {
      lid: 7,
      lch: "排灣族",
    },
    {
      lid: 8,
      lch: "魯凱族",
    },
    {
      lid: 9,
      lch: "太魯閣族",
    },
    {
      lid: 10,
      lch: "噶瑪蘭族",
    },
    {
      lid: 11,
      lch: "鄒族",
    },
    {
      lid: 12,
      lch: "卑南族",
    },
    {
      lid: 13,
      lch: "雅美族",
    },
    {
      lid: 14,
      lch: "撒奇萊雅族",
    },
    {
      lid: 15,
      lch: "卡那卡那富族",
    },
    {
      lid: 16,
      lch: "拉阿魯哇族",
    },
    {
      lid: 99,
      lch: "非原住民",
    },
  ]);

  useEffect(() => {
    getView();
  }, []);

  const getView = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeFormView = process.env.REACT_APP_Application_Route_Form_View;

      const userToken = await cookieManager.getCookieValue(`application`);
      if (!userToken) {
        history.push("/application/login");
        return;
      }

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", id);

      const response = await axios.get(
        `${url}/${routeFormView}?id=${id}&user_token=${userToken}`
      );

      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          const email = await sessionStorage.getItem("email");
          data.email = email;
          setData(data);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const print = () => {
    window.print();
  };

  return (
    <>
      <div style={{ backgroundColor: "white", width: "100%" }}>
        <Container>
          <div style={{ textAlign: "center", paddingTop: "1rem" }}>
            <Button
              style={{
                backgroundColor: common.colorCode.no_E75711,
                border: "none",
              }}
              onClick={print}
            >
              點我列印申請單
            </Button>
          </div>
          <div id={"application"} style={{ width: "100%" }}>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  width: "15%",
                  padding: "0 0 0 0.3rem",
                }}
              >
                編號:
              </div>
              &nbsp;
              <div
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  width: "15%",
                  padding: "0 0 0 0.3rem",
                }}
              >
                2022.12.13版
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <div>
                <img src={downloadPageLogo} style={{ width: "50%" }}></img>
              </div>
              <div className="logo--standardkai">
                原住民族語言人才資料庫申請書
              </div>
            </div>
            <div className="application_date">
              申請日期:{yearROC}年{month}月{day}日
            </div>
            <Table>
              <tbody>
                <tr>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    機關(全銜)
                  </td>
                  <td className="application_table_td" colSpan="2">
                    {data.organ}
                  </td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    單位
                  </td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    {data.department}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">帳號保管人(姓名)</td>
                  <td className="application_table_td" colSpan="2">
                    族語名:{data.keeper_ab} <br /> 華語名:{data.keeper_ch}
                  </td>
                  <td className="application_table_td">族別</td>
                  <td className="application_table_td">
                    {data &&
                      languagesOption.find(
                        (language) => language.lid === data.language
                      )?.lch}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">職稱</td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    {data.job_title}
                  </td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    電子郵件
                    <span style={{ fontSize: "0.5rem" }}>(限公務信箱)</span>
                  </td>
                  <td className="application_table_td" colSpan="2">
                    {data.email}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">手機</td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    {data.phone}
                  </td>
                  <td className="application_table_td" style={{ width: "10%" }}>
                    辦公室電話
                  </td>
                  <td className="application_table_td" colSpan="2">
                    {data.tel}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">申請單位簡敘</td>
                  <td className="application_table_td" colSpan="4">
                    {data.description || (
                      <span style={{ color: "gray" }}>
                        (可提供相關網址、或簡要說明)
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">申請標的</td>
                  <td className="application_table_td" colSpan="4">
                    原住民族語言人才資料庫帳號
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">申請事由</td>
                  <td className="application_table_td" colSpan="4">
                    {data.reason || (
                      <span style={{ color: "gray" }}>
                        有相關計畫書、委託證明等亦請提供
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">申請應用範圍</td>
                  <td className="application_table_td" colSpan="4">
                    {data.use_range || (
                      <span style={{ color: "gray" }}>
                        如族語老師資格查詢及媒合
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td">申請使用時間</td>
                  <td className="application_table_td" colSpan="4">
                    {data.service_end_at !== "null" ? (
                      <div>
                        預計使用至
                        <span style={{ textDecoration: "underline" }}>
                          {data.service_end_at?.substring(0, 4)}
                        </span>
                        年
                        <span style={{ textDecoration: "underline" }}>
                          {data.service_end_at?.substring(5, 7)}
                        </span>
                        月
                        <span style={{ textDecoration: "underline" }}>
                          {data.service_end_at?.substring(8, 10)}
                        </span>
                        日
                      </div>
                    ) : (
                      <div>供業務長期使用</div>
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    className="application_table_td"
                    colSpan="5"
                    style={{ textAlign: "center", backgroundColor: "gray" }}
                  >
                    注意事項
                  </td>
                </tr>
                <tr>
                  <td
                    className="application_table_td"
                    style={{ padding: "0" }}
                    colSpan="5"
                  >
                    <div style={{ padding: "0" }}>
                      1. 僅限於業務必要之範圍使用，不可另做他用。
                    </div>
                    <div style={{ padding: "0" }}>
                      2.
                      應以善良管理人之注意妥為保管及確保資料之安全，並請妥善保管帳號。
                    </div>
                    <div style={{ padding: "0" }}>
                      3.
                      族語人才資料之使用，應遵守個人資料保護法，如有違反應負全責。
                    </div>
                    <div style={{ padding: "0" }}>
                      4.
                      申請資料個人資料僅供本基金會建檔及行必要之聯繫，不另做他用。
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td" colSpan="2">
                    是否同意前述應注意事項
                  </td>
                  <td className="application_table_td" colSpan="3">
                    <input type="checkbox" readOnly checked={true}></input>同意
                  </td>
                </tr>
                <tr>
                  <td className="application_table_td" colSpan="5">
                    申請人及申請單位用印:
                    <Row>
                      <Col style={{ margin: "auto" }}>
                        <div
                          style={{
                            display: "flex",
                            border: "dashed gray",
                            color: "gray",
                            width: "10rem",
                            height: "10rem",
                            textAlign: "center",
                            margin: "auto",
                            fontSize: "1rem",
                            padding: "0",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          申請人
                        </div>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            border: "dashed gray",
                            color: "gray",
                            width: "15rem",
                            height: "15rem",
                            textAlign: "center",
                            margin: "auto",
                            fontSize: "1.5rem",
                            padding: "0",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          機關或單位印
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="application_description">
              申請說明: (1)請將用印後申請表函送本基金會。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2)審核通過後加密電郵帳號密碼一組，請將帳密列入移交。
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ApplicationDownloadPage;
