var axios = {
  get: async (url) => {
    console.log("mock axios object get");
    const mockPromise = new Promise((resolve, reject) => {
      if (url == "https://localhost/gettoken?account=test&password=123") {
        resolve();
      } else if (url.includes("verify_email")) {
        resolve({ status: 200, data: { code: 200 } });
      } else {
        reject("fail");
      }
    });

    mockPromise.data = { data: { token: "token" } };
    return mockPromise;
  },
  post: async (url, body, config) => {
    console.log("mock axios object post");
    const mockPromise = new Promise((resolve, reject) => {
      if (url.includes("/user_application/login")) {
        if (
          typeof body === "object" &&
          body.get("account") === "test" &&
          body.get("password") === "123"
        ) {
          if (config && config.headers["Content-Type"]) {
            resolve({
              status: 200,
              data: {
                code: 200,
                data: {
                  user_id: 0,
                  email: body.get("account"),
                  user_token: body.get("password"),
                },
              },
            });
          }
        }
      }
    });

    return mockPromise;
  },
};

export default axios;

// mock data
let mockData = [
  {
    title: "吳美金 Dawa Lisin ( 72歲, b.1949) | 秀姑巒阿美語",
    skills: ["測驗優級類", "測驗高級類", "族語振興人員研習"],
    address: "台北市中正區愛國西路100號100樓",
    phone: "0955-123456, 02-23456789",
  },
  {
    title: "餑餑 GG PO ( 32歲, b.1949) | GG阿美語",
    skills: ["ssssssssssssss測驗優級類", "測驗高級類", "族語振興人員研習"],
    address: "台北市中正區愛國西路100號100樓",
    phone: "0955-123456, 02-23456789",
  },
  {
    title: "鱗莖 Dawa Lisin ( 72歲, b.1949) | BB阿美語",
    skills: ["aaaaaaaaaaaaa測驗優級類", "測驗高級類", "族語振興人員研習"],
    address: "台北市中正區愛國西路100號100樓",
    phone: "0955-123456, 02-23456789",
  },
  {
    title: "素梅 Dawa Lisin ( 72歲, b.1949) | AA阿美語",
    skills: ["bbbbbbbbbbbbbbb測驗優級類", "測驗高級類", "族語振興人員研習"],
    address: "台北市中正區愛國西路100號100樓",
    phone: "0955-123456, 02-23456789",
  },
  {
    title: "阿災 Dawa Lisin ( 72歲, b.1949) | 阿美語",
    skills: ["ccccccccccccccccccc測驗優級類", "測驗高級類", "族語振興人員研習"],
    address: "台北市中正區愛國西路100號100樓",
    phone: "0955-123456, 02-23456789",
  },
];
