// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Button, Card, Container, Row, Col, Form } from "react-bootstrap";
import ApplicationHeaderComponent from "../../components/ApplicationHeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import UploadFileButtonComponent from "../../components/UploadFileButtonComponent";
import ErrorBoundary from "../../components/ErrorBoundary";
import common from "../../utilities/common";
import cookieManager from "../../utilities/cookiesManager";
import axios from "axios";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";

function ApplicationEditPage(props) {
  const inputStyle = {
    border: "0.15rem solid black",
    backgroundColor: common.colorCode.no_F4F4F4,
    borderRadius: "0",
    margin: "0 0 0.5rem 0",
  };

  const textAreaStyle = { margin: "0 1.5rem 0 1.5rem" };

  let history = useHistory();

  let id;

  if (props.match && props.match.params) {
    id = props.match.params.id;
  }

  // hook state
  const [data, setData] = useState({});
  const [periodOfUse, setPeriodOfUse] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  const [organList, setOrganList] = useState([]);
  const [languagesOption, setLanguagesOption] = useState([
    {
      lid: 1,
      lch: "阿美族",
    },
    {
      lid: 2,
      lch: "泰雅族",
    },
    {
      lid: 3,
      lch: "賽夏族",
    },
    {
      lid: 4,
      lch: "邵族",
    },
    {
      lid: 5,
      lch: "賽德克族",
    },
    {
      lid: 6,
      lch: "布農族",
    },
    {
      lid: 7,
      lch: "排灣族",
    },
    {
      lid: 8,
      lch: "魯凱族",
    },
    {
      lid: 9,
      lch: "太魯閣族",
    },
    {
      lid: 10,
      lch: "噶瑪蘭族",
    },
    {
      lid: 11,
      lch: "鄒族",
    },
    {
      lid: 12,
      lch: "卑南族",
    },
    {
      lid: 13,
      lch: "雅美族",
    },
    {
      lid: 14,
      lch: "撒奇萊雅族",
    },
    {
      lid: 15,
      lch: "卡那卡那富族",
    },
    {
      lid: 16,
      lch: "拉阿魯哇族",
    },
    {
      lid: 99,
      lch: "非原住民",
    },
  ]);

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.prevPath &&
      history.location.state.prevPath.includes("application/form/create")
    ) {
      common.toastEmmiter("若有相關檔案請上傳");
    }

    getOrganList();
    getView();
  }, []);

  useEffect(() => {
    if (!cookieManager.getCookieValue(`application`)) {
      history.push("/application/login");
    }
  });

  const getUserToken = async () => {
    return await cookieManager.getCookieValue(`application`);
  };

  const getView = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeFormView = process.env.REACT_APP_Application_Route_Form_View;

      const userToken = await getUserToken();
      if (!userToken) {
        history.push(`application/login`);
        return;
      }

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", id);

      const response = await axios.get(
        `${url}/${routeFormView}?id=${id}&user_token=${userToken}`
      );

      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          const email = await sessionStorage.getItem("email");
          data.email = email;
          data.id = id;
          setData(data);
          if (data.attached) {
            setAttachedFiles(
              data.attached.map((item) => {
                return {
                  name: item.file_name,
                  path: item.file_path,
                  id: item.id,
                };
              })
            );
          }

          setPeriodOfUse(data.service_end_at ? true : false);

          checkStatus();
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const getOrganList = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeOrganList = process.env.REACT_APP_Application_Route_Organ_List;

      const userToken = await cookieManager.getCookieValue(`application`);
      if (!userToken) {
        history.push("/application/login");
        return;
      }

      const response = await axios.get(`${url}/${routeOrganList}`);

      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          setOrganList([...data]);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const periodOfUseOnChange = (e) => {
    if (e.target && e.target.id) {
      const radioId = e.target.id;
      switch (radioId) {
        case "periodofuseLongTerm":
          setPeriodOfUse(false);
          break;
        case "periodofusePeriod":
          setPeriodOfUse(true);
          setData({ ...data, service_end_at: null });
          break;
        default:
          setPeriodOfUse(false);
          break;
      }
    }
  };

  const inputOnChange = (e) => {
    if (!e.target.id) {
      return;
    }
    if (!e.target.value) {
      return;
    }

    const id = e.target.id;
    const value = e.target.value;

    switch (id) {
      case "belongsTo":
        setData({ ...data, belongs_to: value });
        break;
      case "organ":
        setData({ ...data, organ: value });
        break;
      case "department":
        setData({ ...data, department: value });
        break;
      case "keeperCh":
        setData({ ...data, keeper_ch: value });
        break;
      case "keeperAb":
        setData({ ...data, keeper_ab: value });
        break;
      case "language":
        setData({ ...data, language: value });
        break;
      case "jobTitle":
        setData({ ...data, job_title: value });
        break;
      case "phone":
        setData({ ...data, phone: value });
        break;
      case "tel":
        setData({ ...data, tel: value });
        break;
      case "description":
        setData({ ...data, description: value });
        break;
      case "purpose":
        setData({ ...data, purpose: value });
        break;
      case "reason":
        setData({ ...data, reason: value });
        break;
      case "useRange":
        setData({ ...data, use_range: value });
        break;
      default:
        break;
    }
  };

  const agreementOnChange = (e) => {
    if (e.target) {
      setAgreement(e.target.checked);
    }
  };

  const saveApplication = async () => {
    if (!agreement) {
      common.toastEmmiter("請勾選同意");
      return;
    }
    if (checkRequiredFieldisEmpty()) {
      common.toastEmmiter(checkRequiredFieldisEmpty());
      return;
    }

    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeFormUpdate =
        process.env.REACT_APP_Application_Route_Form_Update;

      const userToken = await cookieManager.getCookieValue("application");

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", data.id);
      formData.append("belongs_to", data.belongs_to);
      formData.append("organ", data.organ);
      formData.append("department", data.department);
      formData.append("keeper_ch", data.keeper_ch);
      formData.append("keeper_ab", data.keeper_ab);
      formData.append("language", data.language);
      formData.append("job_title", data.job_title);
      formData.append("phone", data.phone);
      formData.append("tel", data.tel);
      formData.append("description", data.description);
      formData.append("purpose", data.purpose);
      formData.append("reason", data.reason);
      formData.append("use_range", data.use_range);
      formData.append("service_end_at", data.service_end_at);

      const response = await axios.post(`${url}/${routeFormUpdate}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        common.toastEmmiter("儲存成功");
        getView();
      }
    } catch (error) {
      if (error.response) {
        console.log("error message", error.response.data);
      } else {
        console.error(error);
      }

      alert(error);
    }
  };

  const checkRequiredFieldisEmpty = () => {
    if (!data.belongs_to) {
      return "belongsTo";
    }
    if (!data.organ) {
      return "organ";
    }
    if (!data.department) {
      return "department";
    }
    if (!data.keeper_ch) {
      return "keeperCh";
    }
    if (!data.language) {
      return "language";
    }
    if (!data.job_title) {
      return "jobTitle";
    }
    if (!data.phone) {
      return "phone";
    }
    if (!data.tel) {
      return "tel";
    }
    if (!data.description) {
      return "description";
    }
    if (!data.purpose) {
      return "purpose";
    }
    if (!data.reason) {
      return "reason";
    }
    if (!data.use_range) {
      return "useRange";
    }
    if (!data.service_end_at) {
      return "serviceEndAt";
    }

    return "";
  };

  const backToList = () => {
    history.push("/application/list");
  };

  const lockApplication = async () => {
    const userToken = await cookieManager.getCookieValue("application");
    if (!userToken) {
      history.push(`application/login`);
      return;
    }

    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeLockApplication =
        process.env.REACT_APP_Application_Route_Form_LockApplication;

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", data.id);

      const response = await axios.post(
        `${url}/${routeLockApplication}`,
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        common.toastEmmiter("已鎖定申請單");
        setIsLock(true);

        setTimeout(() => {
          history.push("/application/list");
        }, 5000);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.error(error);
      }

      alert(error);
    }
  };

  const checkStatus = async () => {
    const url = process.env.REACT_APP_Application_Url;
    const getListRoute = process.env.REACT_APP_Application_Route_Get_List;

    const userToken = await getUserToken();

    if (!userToken) {
      history.push("/application/login");
    }

    try {
      const formData = new FormData();
      formData.append("user_token", userToken);

      const response = await axios.get(
        `${url}/${getListRoute}?user_token=${userToken}`
      );

      if (response.status === 200) {
        const applications = response.data.applications;
        if (applications && applications.length > 0) {
          const found = applications.find((app) => app.id.toString() === id);
          if (found && found.status.toString() !== "0") {
            setIsLock(true);
          }
        } else {
        }
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const uploadAttachedFile = async (e) => {
    if (!e.target) {
      common.toastEmmiter("沒有檔案");
      return;
    }
    if (e.target.files.length === 0) {
      common.toastEmmiter("沒有檔案");
      return;
    }

    const userToken = await cookieManager.getCookieValue(`application`);
    if (!userToken) {
      history.push(`application/login`);
      return;
    }

    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeUploadAttached =
        process.env.REACT_APP_Application_Route_Form_UploadAttached;

      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", id);
      formData.append("file", file);

      const response = await axios.post(
        `${url}/${routeUploadAttached}`,
        formData,
        { headers: { "Content-type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data && data.attached) {
          setAttachedFiles(
            data.attached.map((item) => {
              return {
                name: item.file_name,
                path: item.file_path,
                id: item.id,
              };
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const deleteAttachedFile = async (e) => {
    if (!e.target.id) {
      alert("id does not exist");
      return;
    }

    const attachedId = e.target.id;

    const userToken = await cookieManager.getCookieValue(`application`);
    if (!userToken) {
      history.push(`application/login`);
      return;
    }

    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeDeleteAttached =
        process.env.REACT_APP_Application_Route_Form_DeleteAttached;

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", attachedId);

      const response = await axios.post(
        `${url}/${routeDeleteAttached}`,
        formData,
        { headers: { "Content-type": "multipart/form-data" } }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data && data.attached) {
          setAttachedFiles(
            data.attached.map((item) => {
              return {
                name: item.file_name,
                path: item.file_path,
                id: item.id,
              };
            })
          );
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const renderAttachedFiles = () => {
    return attachedFiles.map((attachedFile) => {
      return (
        <div key={attachedFile.id} style={{ margin: "0 0 0.5rem 0" }}>
          <Button
            style={{ backgroundColor: "white", color: "blue", border: "none" }}
            onClick={() => {
              window.open(attachedFile.path);
            }}
          >
            {attachedFile.name}
          </Button>
          <Button
            id={attachedFile.id}
            style={{
              backgroundColor: common.colorCode.no_7D8E96,
              borderRadius: "1rem",
              border: "none",
            }}
            onClick={deleteAttachedFile}
          >
            {"刪除"}
          </Button>
        </div>
      );
    });
  };

  return (
    <>
      <ApplicationHeaderComponent
        logoutButtonText={"登出"}
        onClickLogoutButton={() => {
          cookieManager.setCookie("application", "", 0);
          history.push("/application/login");
        }}
      ></ApplicationHeaderComponent>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "0.5rem",
            border: "none",
            minHeight: "50vh",
          }}
        >
          <Card.Body style={{ fontSize: "1.3rem" }}>
            <Card.Title style={{ fontStyle: "bold", fontSize: "1.5rem" }}>
              填寫帳號申請單{" "}
            </Card.Title>
            <hr></hr>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  所屬上級機關
                </div>
              </Col>
              <Col md={10}>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "0.15rem solid black",
                    backgroundColor: common.colorCode.no_F4F4F4,
                    borderRadius: "0",
                    margin: "0 0 0.5rem 0",
                  }}
                  id={"belongsTo"}
                  value={data.belongs_to}
                  onChange={inputOnChange}
                >
                  {organList.map((organ) => {
                    return (
                      <option key={organ.id} value={organ.id}>
                        {organ.organ_code + " " + organ.organ_name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  機關全銜
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.organ}
                  id={"organ"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  單位
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.department}
                  id={"department"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  帳號保管人族語名
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.keeper_ab}
                  id={"keeperAb"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  帳號保管人華語名
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.keeper_ch}
                  id={"keeperCh"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  職稱
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.job_title}
                  id={"jobTitle"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  電子郵件
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.email}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  手機
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.phone}
                  id={"phone"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  辦公室電話
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.tel}
                  id={"tel"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  族別
                </div>
              </Col>
              <Col md={10}>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "0.15rem solid black",
                    backgroundColor: common.colorCode.no_F4F4F4,
                    borderRadius: "0",
                    margin: "0 0 0.5rem 0",
                  }}
                  value={data.language}
                  id={"language"}
                  onChange={inputOnChange}
                >
                  {languagesOption.map((lang) => {
                    return (
                      <option value={lang.lid} key={lang.lid}>
                        {lang.lch}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  申請標的
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  defaultValue={data.purpose}
                  id={"purpose"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Col>
            </Row>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請單位簡敘
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  (可提供相關網址，或簡要說明)
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  defaultValue={data.description}
                  id={"description"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Row>
            </>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請事由
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  ex:因辦理__________(機關)___________________________(計畫或業務名稱)
                  相關計畫書、委託證明等亦請提供
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  defaultValue={data.reason}
                  id={"reason"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Row>
            </>
            <Row style={textAreaStyle}>
              <Col md={2}>
                <UploadFileButtonComponent
                  buttonStyle={{
                    backgroundColor: common.colorCode.no_E75711,
                    color: "white",
                    border: "none",
                  }}
                  accept={".pdf,.docx,.xls,.xlsx"}
                  onClick={uploadAttachedFile}
                >
                  上傳檔案
                </UploadFileButtonComponent>
              </Col>
              <Col md={10}>{renderAttachedFiles()}</Col>
            </Row>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請應用範圍
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  (如族語老師資格查詢及媒合)
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  defaultValue={data.use_range}
                  id={"useRange"}
                  onChange={inputOnChange}
                ></Form.Control>
              </Row>
            </>
            <>
              <Row style={textAreaStyle}>
                <Col md={4}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請使用期限
                </Col>
                <Col md={4}>
                  <Form>
                    <Form.Check
                      reverse={"true"}
                      type="radio"
                      label={"供業務長期使用"}
                      name={"periodofuse"}
                      id={"periodofuseLongTerm"}
                      checked={!periodOfUse}
                      onChange={periodOfUseOnChange}
                    ></Form.Check>
                    <Form.Check
                      reverse={"true"}
                      type="radio"
                      label={"計畫使用時間"}
                      name={"periodofuse"}
                      id={"periodofusePeriod"}
                      checked={periodOfUse}
                      onChange={periodOfUseOnChange}
                    ></Form.Check>
                  </Form>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={12}>&nbsp;</Col>
                  </Row>
                  <Row>
                    {periodOfUse && (
                      <>
                        <Col md={4} style={{ whiteSpace: "nowrap" }}>
                          使用至
                        </Col>
                        <Col md={8}>
                          <DatePicker
                            className="form-control"
                            selected={Date.parse(data.service_end_at)}
                            dateFormat="yyyy/MM/dd"
                            onChange={(date) => {
                              const dateFormat = date.toLocaleDateString();
                              setData({
                                ...data,
                                service_end_at: dateFormat,
                              });
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
            </>
            <hr></hr>
            <div style={{ textAlign: "center" }}>注意事項</div>
            <div
              style={{
                textAlign: "left",
                margin: "0 10rem 2rem 10rem",
                color: common.colorCode.no_FF0000,
              }}
            >
              <Row style={{ fontSize: "1rem" }}>
                1. 僅限於業務必要之範圍使用，不可另做他用。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                2.
                應以善良管理人之注意妥為保管及確保資料之安全，並請妥善保管帳號。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                3. 族語人才資料之使用，應遵守個人資料保護法，如有違反應負全責。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                申請資料個人資料僅供本基金會建檔及行必要之聯繫，不另做他用。
              </Row>
            </div>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={agreementOnChange}
                ></input>
                <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                是否同意上述應注意事項
              </div>
            </div>
            <br></br>
            <br></br>
            <div style={{ textAlign: "center", margin: "0 0 2rem 0" }}>
              <Row>
                <Col>
                  <Button
                    style={{
                      backgroundColor: common.colorCode.no_7D8E96,
                      border: "none",
                      borderRadius: "1rem",
                      fontSize: "2rem",
                    }}
                    onClick={backToList}
                  >
                    返回
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={isLock}
                    style={{
                      backgroundColor: common.colorCode.no_E75711,
                      border: "none",
                      borderRadius: "1rem",
                      fontSize: "2rem",
                    }}
                    onClick={saveApplication}
                  >
                    儲存
                  </Button>
                </Col>
              </Row>
            </div>
            <div
              style={{
                textAlign: "center",
                color: common.colorCode.no_E75711,
                margin: "0 0 3rem 0",
              }}
            >
              注意！申請單送出後則無法再修改，請確認資料填寫是否正確
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={isLock}
                style={{
                  backgroundColor: common.colorCode.no_C18A13,
                  border: "none",
                  borderRadius: "1rem",
                  fontSize: "2rem",
                }}
                onClick={lockApplication}
              >
                確認送出申請
              </Button>
            </div>
          </Card.Body>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationEditPage;
