// common
import common from "../utilities/common";

import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

/**
 * Open Create Form
 * and bring some attribute values from current form
 * @param {string} labelText: display text
 * @param {string[]} dropdownItem: dropdown item array
 * @param {width}: width of dropdown list
 * @param {set}: callback function for setting parameters 
 * @author hsutinghuan
 */
function DropDownList({ icon, labelText, dropdownItem, width, set, defaultValue }) {
    const [selected, setSelected] = useState(dropdownItem == undefined ? `無相關條件` : dropdownItem[0].name);

    useEffect(() => {
        if (defaultValue == undefined) return;
        handleSelect(defaultValue);
    }, [defaultValue])

    useEffect(() => {
        setSelected(dropdownItem == undefined ? `無相關條件` : dropdownItem[0].name);
    }, [dropdownItem])

    function handleSelect(event) {
        const findItem = dropdownItem.find(item => {
            return item.id.toString() === event.toString();
        });

        setSelected(findItem.name);
        set(event);
    }

    return (<>
        <div>
            <span className="material-icons" style={{ color: common.colorCode.no_AA7D24 }}>{icon}</span> <label style={{ color: common.colorCode.no_AA7D24 }}>{labelText}</label>
        </div>
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle style={{ width: width, backgroundColor: common.colorCode.no_F2F2F2, borderStyle: "none", outlineOffset: "none", color: "black", textAlign: "left" }} id="dropdown-basic">
                <span style={{ width: "90%" }}>{selected}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    dropdownItem == undefined ? `無相關條件` :
                        dropdownItem.map((item, index) => {
                            return <Dropdown.Item eventKey={item.id} key={`${index}`}>{item.name}</Dropdown.Item>;
                        })
                }
            </Dropdown.Menu>
        </Dropdown>
    </>)
}

export default DropDownList;
