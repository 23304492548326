import logoApplicationLogin from "../assets/img/logoApplicationLogin.jpg";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Navbar, Container, Table, Form } from "react-bootstrap";
import common from "../utilities/common";

function ApplicationHeaderComponent({
  email,
  logoutButtonText,
  onClickLogoutButton,
}) {
  const [emailDisplay, setEmailDisplay] = useState(email);

  useEffect(() => {
    if (!email) {
      setEmailDisplay(sessionStorage.getItem("email"));
    }
  });

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
          <div style={{ margin: "5rem 0 0 0" }}>
            {emailDisplay}
            <Button
              onClick={onClickLogoutButton}
              style={{
                backgroundColor: common.colorCode.no_E75711,
                border: "none",
                borderRadius: "1rem",
                fontSize: "1rem",
              }}
            >
              {logoutButtonText}
            </Button>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default ApplicationHeaderComponent;
