import common from "../utilities/common";

/**
 * 圓圈元件
 * @param {boolean} checked 是否填滿圓圈
 * @returns {reactComponent} CircleCheckComponent
 */
function CircleCheckComponent({ checked = false }) {
  const outterCircleStyle = {
    height: "1.5rem",
    width: "1.5rem",
    backgroundColor: "white",
    borderRadius: "50%",
    display: "inline-block",
    border: "0.2rem solid #E75711",
    textAlign: "center",
  };

  const innerCircleStyle = {
    position: "relative",
    height: "85%",
    width: "85%",
    backgroundColor: checked && "#E75711",
    borderRadius: "50%",
    margin: "auto",
    marginTop: "0.065rem",
    marginLeft: "0.065rem",
  };

  return (
    <>
      {checked ? (
        <span
          style={{ color: common.colorCode.no_E75711 }}
          className="material-icons-outlined"
        >
          radio_button_checked
        </span>
      ) : (
        <span
          style={{ color: common.colorCode.no_E75711 }}
          className="material-icons-outlined"
        >
          radio_button_unchecked
        </span>
      )}
    </>
  );
}

export default CircleCheckComponent;
