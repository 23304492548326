import { Row, Col, Collapse } from "react-bootstrap";
import CircleCheckComponent from "./CircleCheckComponent";

/**
 * 進度條元件
 * @param {string} progress 0, 1, 2, 3, 4 分別從左至右顯示進度
 * @returns {reactComponent} ProgressBarComponent
 */
function ProgressBarComponent({ progress }) {
  let checked0 = true;
  let checked1 = false;
  let checked2 = false;
  let checked3 = false;
  let checked4 = false;

  if (progress === "1") {
    checked1 = true;
  } else if (progress === "2") {
    checked1 = true;
    checked2 = true;
  } else if (progress === "3") {
    checked1 = true;
    checked2 = true;
    checked3 = true;
  } else if (progress === "4") {
    checked1 = true;
    checked2 = true;
    checked3 = true;
    checked4 = true;
  } else if (progress === "5") {
    checked1 = true;
    checked2 = true;
    checked3 = true;
    checked4 = true;
  }

  const lineStyle = {
    borderTop: "0.2rem solid #E75711",
    display: "inline-block",
    width: "100%",
    height: "0.75rem",
  };

  return (
    <>
      <Row>
        <Col style={{ flex: "0", padding: "0", textAlign: "center" }}>
          <CircleCheckComponent checked={checked0}></CircleCheckComponent>
        </Col>
        <Col style={{ padding: "0" }}>
          <div style={lineStyle}></div>
        </Col>
        <Col style={{ flex: "0", padding: "0", textAlign: "center" }}>
          <CircleCheckComponent checked={checked1}></CircleCheckComponent>
        </Col>
        <Col style={{ padding: "0" }}>
          <div style={lineStyle}></div>
        </Col>
        <Col style={{ flex: "0", padding: "0", textAlign: "center" }}>
          <CircleCheckComponent checked={checked2}></CircleCheckComponent>
        </Col>
        <Col style={{ padding: "0" }}>
          <div style={lineStyle}></div>
        </Col>
        {progress !== "5" ? (
          <Col style={{ flex: "0", padding: "0", textAlign: "center" }}>
            <CircleCheckComponent checked={checked3}></CircleCheckComponent>
          </Col>
        ) : (
          <></>
        )}
        <Col style={{ padding: "0" }}>
          <div style={lineStyle}></div>
        </Col>
        <Col style={{ flex: "0", padding: "0", textAlign: "center" }}>
          <CircleCheckComponent checked={checked4}></CircleCheckComponent>
        </Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
}

export default ProgressBarComponent;
