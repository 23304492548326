// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import ApplicationHeaderComponent from "../../components/ApplicationHeaderComponent";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";
import axios from "axios";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";

function ApplicationViewPage(props) {
  const inputStyle = {
    border: "0.15rem solid black",
    backgroundColor: common.colorCode.no_F4F4F4,
    borderRadius: "0",
    margin: "0 0 0.5rem 0",
  };

  const textAreaStyle = { margin: "0 1.5rem 0 1.5rem" };

  let history = useHistory();

  let id;

  if (props.match && props.match.params) {
    id = props.match.params.id;
  }

  // hook state
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState([]);
  const [languagesOption, setLanguagesOption] = useState([
    {
      lid: 1,
      lch: "阿美族",
    },
    {
      lid: 2,
      lch: "泰雅族",
    },
    {
      lid: 3,
      lch: "賽夏族",
    },
    {
      lid: 4,
      lch: "邵族",
    },
    {
      lid: 5,
      lch: "賽德克族",
    },
    {
      lid: 6,
      lch: "布農族",
    },
    {
      lid: 7,
      lch: "排灣族",
    },
    {
      lid: 8,
      lch: "魯凱族",
    },
    {
      lid: 9,
      lch: "太魯閣族",
    },
    {
      lid: 10,
      lch: "噶瑪蘭族",
    },
    {
      lid: 11,
      lch: "鄒族",
    },
    {
      lid: 12,
      lch: "卑南族",
    },
    {
      lid: 13,
      lch: "雅美族",
    },
    {
      lid: 14,
      lch: "撒奇萊雅族",
    },
    {
      lid: 15,
      lch: "卡那卡那富族",
    },
    {
      lid: 16,
      lch: "拉阿魯哇族",
    },
    {
      lid: 99,
      lch: "非原住民",
    },
  ]);

  useEffect(() => {
    getOrganList();
    getView();
  }, []);

  useEffect(() => {
    if (!cookieManager.getCookieValue(`application`)) {
      history.push("/application/login");
    }
  });

  const getView = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeFormView = process.env.REACT_APP_Application_Route_Form_View;

      const userToken = await cookieManager.getCookieValue(`application`);
      if (!userToken) {
        history.push("/application/login");
        return;
      }

      const formData = new FormData();
      formData.append("user_token", userToken);
      formData.append("id", id);

      const response = await axios.get(
        `${url}/${routeFormView}?id=${id}&user_token=${userToken}`
      );

      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          const email = await sessionStorage.getItem("email");
          data.email = email;
          setData(data);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const getOrganList = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeOrganList = process.env.REACT_APP_Application_Route_Organ_List;

      const userToken = await cookieManager.getCookieValue(`application`);
      if (!userToken) {
        history.push("/application/login");
        return;
      }

      const response = await axios.get(`${url}/${routeOrganList}`);

      if (response && response.status === 200) {
        const data = response.data;
        if (data) {
          setOrganList([...data]);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const backToList = () => {
    history.push("/application/list");
  };

  return (
    <>
      <ApplicationHeaderComponent
        logoutButtonText={"登出"}
        onClickLogoutButton={() => {
          cookieManager.setCookie("application", "", 0);
          history.push("/application/login");
        }}
      ></ApplicationHeaderComponent>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: "0.5rem",
            border: "none",
            minHeight: "50vh",
          }}
        >
          <Card.Body style={{ fontSize: "1.3rem" }}>
            <Card.Title style={{ fontStyle: "bold", fontSize: "1.5rem" }}>
              填寫帳號申請單{" "}
            </Card.Title>
            <hr></hr>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  所屬上級機關
                </div>
              </Col>
              <Col md={10}>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "0.15rem solid black",
                    backgroundColor: common.colorCode.no_F4F4F4,
                    borderRadius: "0",
                    margin: "0 0 0.5rem 0",
                  }}
                >
                  {organList.map((organ) => {
                    if (organ.id.toString() === data.belongs_to?.toString()) {
                      return (
                        <option key={organ.id} value={organ.id}>
                          {organ.organ_code + " " + organ.organ_name}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  機關全銜
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.organ}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  單位
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.department}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  帳號保管人族語名
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.keeper_ab}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  帳號保管人華語名
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.keeper_ch}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  職稱
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.job_title}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  電子郵件
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  disabled
                  style={inputStyle}
                  defaultValue={data.email}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  手機
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.phone}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  辦公室電話
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  style={inputStyle}
                  disabled
                  defaultValue={data.tel}
                ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right" }}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  族別
                </div>
              </Col>
              <Col md={10}>
                <Form.Select
                  aria-label="Default select example"
                  style={{
                    border: "0.15rem solid black",
                    backgroundColor: common.colorCode.no_F4F4F4,
                    borderRadius: "0",
                    margin: "0 0 0.5rem 0",
                  }}
                  id={"language"}
                >
                  {languagesOption.map((lang) => {
                    if (lang.lid?.toString() === data.language?.toString()) {
                      return <option key={lang.lid} value={lang.lid}>{lang.lch}</option>;
                    }
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  申請標的
                </div>
              </Col>
              <Col md={10}>
                <Form.Control
                  disabled
                  style={inputStyle}
                  defaultValue={data.purpose}
                ></Form.Control>
              </Col>
            </Row>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請單位簡敘
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  (可提供相關網址，或簡要說明)
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  disabled
                  defaultValue={data.description}
                ></Form.Control>
              </Row>
            </>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請事由
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  ex:因辦理__________(機關)___________________________(計畫或業務名稱)
                  相關計畫書、委託證明等亦請提供
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  disabled
                  defaultValue={data.reason}
                ></Form.Control>
              </Row>
            </>
            <>
              <Row style={textAreaStyle}>
                <Col md={2}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請應用範圍
                </Col>
              </Row>
              <Row style={textAreaStyle}>
                <span
                  style={{
                    color: common.colorCode.no_E75711,
                    fontSize: "1rem",
                  }}
                >
                  (如族語老師資格查詢及媒合)
                </span>
              </Row>
              <Row style={textAreaStyle}>
                <Form.Control
                  as="textarea"
                  style={inputStyle}
                  disabled
                  defaultValue={data.use_range}
                ></Form.Control>
              </Row>
            </>
            <>
              <Row style={textAreaStyle}>
                <Col md={4}>
                  <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                  申請使用期限
                </Col>
                <Col md={4}>
                  <Form>
                    <Form.Check
                      reverse={"true"}
                      type="radio"
                      label={"供業務長期使用"}
                      name={"periodofuse"}
                      id={"periodofuseLongTerm"}
                      checked={data.service_end_at || true}
                      readOnly
                    ></Form.Check>
                    <Form.Check
                      reverse={"true"}
                      type="radio"
                      label={"計畫使用時間"}
                      name={"periodofuse"}
                      id={"periodofusePeriod"}
                      checked={data.service_end_at && true}
                      readOnly
                    ></Form.Check>
                  </Form>
                </Col>
                <Col md={4}>
                  <Row>
                    <Col md={12}>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col md={4} style={{ whiteSpace: "nowrap" }}>
                      使用至
                    </Col>
                    <Col md={8}>
                      <DatePicker
                        className="form-control"
                        selected={Date.parse(data.service_end_at)}
                        dateFormat="yyyy/MM/dd"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
            <hr></hr>
            <div style={{ textAlign: "center" }}>注意事項</div>
            <div
              style={{
                textAlign: "left",
                margin: "0 10rem 2rem 10rem",
                color: common.colorCode.no_FF0000,
              }}
            >
              <Row style={{ fontSize: "1rem" }}>
                1. 僅限於業務必要之範圍使用，不可另做他用。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                2.
                應以善良管理人之注意妥為保管及確保資料之安全，並請妥善保管帳號。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                3. 族語人才資料之使用，應遵守個人資料保護法，如有違反應負全責。
              </Row>
              <Row style={{ fontSize: "1rem" }}>
                申請資料個人資料僅供本基金會建檔及行必要之聯繫，不另做他用。
              </Row>
            </div>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked
                ></input>{" "}
                <span style={{ color: common.colorCode.no_FF0000 }}>*</span>
                是否同意上述應注意事項
              </div>
            </div>
            <br></br>
            <br></br>
            <div style={{ textAlign: "center", margin: "0 0 2rem 0" }}>
              <Row>
                <Col>
                  <Button
                    style={{
                      backgroundColor: common.colorCode.no_7D8E96,
                      border: "none",
                      borderRadius: "1rem",
                      fontSize: "2rem",
                    }}
                    onClick={backToList}
                  >
                    返回
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled
                    style={{
                      backgroundColor: common.colorCode.no_E75711,
                      border: "none",
                      borderRadius: "1rem",
                      fontSize: "2rem",
                    }}
                  >
                    儲存
                  </Button>
                </Col>
              </Row>
            </div>
            <div
              style={{
                textAlign: "center",
                color: common.colorCode.no_E75711,
                margin: "0 0 3rem 0",
              }}
            >
              注意！申請單送出後則無法再修改，請確認資料填寫是否正確
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled
                style={{
                  backgroundColor: common.colorCode.no_C18A13,
                  border: "none",
                  borderRadius: "1rem",
                  fontSize: "2rem",
                }}
              >
                確認送出申請
              </Button>
            </div>
          </Card.Body>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationViewPage;
