import { useState, useEffect, useContext } from "react";
import { Button, Row, Col } from "react-bootstrap";
import common from "../utilities/common";
import DropDownList from "./DropDownList";

// hr context
import { FilterAndSearchContext } from "./FilterAndSearchUpperCard";

/**
 * dropdown list with button 
 * @param {String} category region or skill
 * @param {Array} dropdownListArray dropdown list array
 * @param {Array} defaultValue default dropdown list 
 * @param {Number} conditionsQuantity dropdown list quantity
 * @param {String} clearButtonText text of clear button
 * @param {Boolean} horizontal display method
 * @param {Boolean} clearAll if true, clear all states
 * @returns {JSX} DropDownListButtonGroup Component
 */
export default function DropDownListButtonGroup({ category, dropdownListArray, defaultValue, conditionsQuantity, clearButtonText, horizontal = true, clearAll = false }) {
    let addButtonText = <div><div style={{ fontSize: `1.2em`, display: 'inline' }}><strong>{`+`}</strong></div><div style={{ display: `inline` }}>{`搜尋條件`}</div></div>;

    const context = useContext(FilterAndSearchContext);

    // selected conditions that be added ( only for display )
    const [selectedConditions, setSelectedConditions] = useState([]);

    // selected item id
    const [firstSelect, setFirstSelect] = useState(dropdownListArray[0].dropdownItem[0].id);
    const [secondSelect, setSecondSelect] = useState(undefined);
    const [thirdSelect, setThirdSelect] = useState(undefined);

    // select item list
    const [firstDropdownItem, setFirstDropdownItem] = useState(dropdownListArray[0].dropdownItem);
    const [secondDropdownItem, setSecondDropdownItem] = useState(undefined);
    const [thirdDropdownItem, setThirdDropdownItem] = useState(undefined);

    useState(() => {
        if (defaultValue == undefined) {
            return;
        }

        let selected = [];
        let selectedString = "";

        if (category === `region`) {
            setFirstSelect(defaultValue.area);
            setSecondSelect(defaultValue.city);
            setThirdSelect(defaultValue.region);

            for (let i = 0; i < defaultValue.length; i++) {
                if (defaultValue[i].area != undefined) {
                    selectedString += dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].area.toString() === x.id.toString() }).name;
                }
                if (defaultValue[i].area != undefined && defaultValue[i].city != undefined) {
                    const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].area.toString() === x.id.toString() });
                    const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);

                    selectedString += " - " + dropdownListArray[1].dropdownItem[index1].find(x => { return defaultValue[i].city.toString() === x.id.toString() }).name;
                }
                if (defaultValue[i].area != undefined && defaultValue[i].city != undefined && defaultValue[i].region != undefined) {
                    const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].area.toString() === x.id.toString() });
                    const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);
                    const secondSelectedObj = dropdownListArray[1].dropdownItem[index1].find(x => { return defaultValue[i].city.toString() === x.id.toString() });
                    const index2 = dropdownListArray[1].dropdownItem[index1].indexOf(secondSelectedObj);

                    selectedString += " - " + dropdownListArray[2].dropdownItem[index1][index2].find(x => { return defaultValue[i].region.toString() === x.id.toString() }).name;
                }

                selected.push(selectedString);
            }
        }

        if (category === `expert`) {
            setFirstSelect(defaultValue.skill);
            setSecondSelect(defaultValue.dialect);
            setThirdSelect(defaultValue.year);

            for (let i = 0; i < defaultValue.length; i++) {
                if (defaultValue[i].skill != undefined) {
                    selectedString += dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].skill.toString() === x.id.toString() }).name;
                }
                if (defaultValue[i].skill != undefined && defaultValue[i].dialect != undefined) {
                    const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].skill.toString() === x.id.toString() });
                    const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);

                    selectedString += " - " + dropdownListArray[1].dropdownItem[index1].find(x => { return defaultValue[i].dialect.toString() === x.id.toString() }).name;
                }
                if (defaultValue[i].skill != undefined && defaultValue[i].dialect != undefined && defaultValue[i].year != undefined) {
                    const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return defaultValue[i].skill.toString() === x.id.toString() });
                    const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);
                    const secondSelectedObj = dropdownListArray[1].dropdownItem[index1].find(x => { return defaultValue[i].dialect.toString() === x.id.toString() });
                    const index2 = dropdownListArray[1].dropdownItem[index1].indexOf(secondSelectedObj);

                    selectedString += " - " + dropdownListArray[2].dropdownItem[index1][index2].find(x => { return defaultValue[i].year.toString() === x.id.toString() }).name;
                }

                selected.push(selectedString);
            }
        }

        setSelectedConditions(selected);
    }, [defaultValue])

    // when first one (most left) be selected
    useEffect(() => {
        if (dropdownListArray[1].dropdownItem == undefined || firstSelect == undefined) {
            return;
        }

        const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return firstSelect.toString() === x.id.toString() });
        const index = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);

        setSecondDropdownItem(dropdownListArray[1].dropdownItem[index]);

        // default id is first item of array
        if (!(index < 0) && dropdownListArray[1].dropdownItem[index]) {
            setSecondSelect(dropdownListArray[1].dropdownItem[index][0].id);
        }
        else {
            setSecondSelect(undefined);
        }
    }, [firstSelect])

    // when second one (middle) be selected
    useEffect(() => {
        if (dropdownListArray[2].dropdownItem == undefined || secondSelect == undefined) {
            return;
        }

        const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return firstSelect.toString() === x.id.toString() });
        const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);

        if (!(index1 < 0) && dropdownListArray[1].dropdownItem[index1]) {
            const secondSelectedObj = dropdownListArray[1].dropdownItem[index1].find(x => { return secondSelect === x.id.toString() });
            const index2 = dropdownListArray[1].dropdownItem[index1].indexOf(secondSelectedObj);

            setThirdDropdownItem(dropdownListArray[2].dropdownItem[index1][index2]);

            // default id is first item of array
            if (!(index2 < 0) && dropdownListArray[2].dropdownItem[index1][index2]) {
                setThirdSelect(dropdownListArray[2].dropdownItem[index1][index2][0].id);
            }
            else {
                setThirdSelect(undefined);
            }
        }
    }, [secondSelect])

    // clear all added conditions
    useEffect(() => {
        if (clearAll === false) return;
        setSelectedConditions([]);

        setFirstDropdownItem(dropdownListArray[0].dropdownItem);
        setSecondDropdownItem(dropdownListArray[1].dropdownItem[0]);
        setThirdDropdownItem(undefined);
        
        setFirstSelect(dropdownListArray[0].dropdownItem[0].id);
        setSecondSelect(undefined);
        setThirdSelect(undefined);
    }, [clearAll])

    if (dropdownListArray == undefined) {
        return <></>
    }

    // set data to context and seletedString 
    function handleAdd(e) {
        if (selectedConditions.length >= conditionsQuantity) {
            common.toastEmmiter(`已達上限`);
            return;
        }

        let selected = [...selectedConditions];
        let selectedString = "";
        let tempArray = [];

        if (firstSelect != undefined) {
            selectedString += dropdownListArray[0].dropdownItem.find(x => { return firstSelect.toString() === x.id.toString() }).name;
            tempArray.push(firstSelect.toString());
        }
        if (firstSelect != undefined && secondSelect != undefined) {
            const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return firstSelect.toString() === x.id.toString() });
            const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);

            selectedString += " - " + dropdownListArray[1].dropdownItem[index1].find(x => { return secondSelect.toString() === x.id.toString() }).name;
            tempArray.push(secondSelect.toString());
        }
        if (firstSelect != undefined && secondSelect != undefined && thirdSelect != undefined) {
            const firstSelectedObj = dropdownListArray[0].dropdownItem.find(x => { return firstSelect.toString() === x.id.toString() });
            const index1 = dropdownListArray[0].dropdownItem.indexOf(firstSelectedObj);
            const secondSelectedObj = dropdownListArray[1].dropdownItem[index1].find(x => { return secondSelect.toString() === x.id.toString() });
            const index2 = dropdownListArray[1].dropdownItem[index1].indexOf(secondSelectedObj);

            selectedString += " - " + dropdownListArray[2].dropdownItem[index1][index2].find(x => { return thirdSelect.toString() === x.id.toString() }).name;
            tempArray.push(thirdSelect.toString());
        }

        if (IsDuplicate(selected, selectedString)) {
            common.toastEmmiter(`條件重複`);
            return;
        }

        selected.push(selectedString);

        let selectedDataObj = {};
        tempArray.forEach((item, index) => {
            if (category === "region") {
                if (index === 0) selectedDataObj.area = item;
                if (index === 1) selectedDataObj.city = item;
                if (index === 2) selectedDataObj.township = item;
            }

            if (category === "expert") {
                if (index === 0) selectedDataObj.skill = item;
                if (index === 1) selectedDataObj.dialect = item;
                if (index === 2) selectedDataObj.year = item;
            }
        });

        if (category === "region") {
            tempArray = [...context.selectedLocationArray];
            if (IsConditionDuplicated(tempArray, selectedDataObj)) {
                common.toastEmmiter(`條件衝突`);
                return;
            };

            tempArray.push(selectedDataObj);
            context.setSelectedLocationArray(tempArray);
        }
        else if (category === "expert") {
            tempArray = [...context.selectedExpertArray];
            if (IsConditionDuplicated(tempArray, selectedDataObj)) {
                common.toastEmmiter(`條件衝突`);
                return;
            };

            tempArray.push(selectedDataObj);
            context.setSelectedExpertArray(tempArray);
        }

        setSelectedConditions(selected);
    }

    const handleCancel = (e) => {
        let index = e.target.id;

        if (category === "region") {
            let tempArray = [...context.selectedLocationArray];
            tempArray.splice(index, 1);
            context.setSelectedLocationArray(tempArray);
        }
        else if (category === "expert") {
            let tempArray = [...context.selectedExpertArray];
            tempArray.splice(index, 1);
            context.setSelectedExpertArray(tempArray);
        }

        let tempSelected = [...selectedConditions];
        tempSelected.splice(index, 1);

        setSelectedConditions(tempSelected);
    }

    const handleClear = () => {
        setSelectedConditions([]);

        if (category === "region") {
            context.setSelectedLocationArray([]);
        }
        else if (category === "expert") {
            context.setSelectedExpertArray([]);
        }
    }

    function IsConditionDuplicated(array, object) {
        let result = false;
        array.forEach(obj => {
            const keys = Object.keys(obj);

            // compare first condition
            if (object[`area`]) {
                if (object[`area`] == 0 || obj[`area`] == 0) result = true;
                if (object[`area`] === obj[`area`]) {
                    // compare second condition
                    if (object[`city`]) {
                        if (object[`city`] == 0 || obj[`city`] == 0) result = true;
                        if (object[`city`] === obj[`city`]) {
                            // compare third condition
                            if (object[`township`]) {
                                if (object[`township`] == 0 || obj[`township`] == 0) result = true;
                                if (object[`township`] === obj[`township`]) {
                                    result = true;
                                }
                            }
                        }
                    }
                }
            }

            // compare first condition
            if (object[`skill`]) {
                if (object[`skill`] == 0 || obj[`skill`] == 0) result = true;
                if (object[`skill`] === obj[`skill`]) {
                    // compare second condition
                    if (object[`dialect`]) {
                        if (object[`dialect`] == 0 || obj[`dialect`] == 0) result = true;
                        if (object[`dialect`] === obj[`dialect`]) {
                            // compare third condition
                            if (object[`year`]) {
                                if (object[`year`] == 0 || obj[`year`] == 0) result = true;
                                if (object[`year`] === obj[`year`]) {
                                    result = true;
                                }
                            }
                        }
                    }
                }
            }
        })

        return result;
    }

    function IsDuplicate(array, value) {
        return array.find(x => { return x === value }) != undefined;
    }

    return (<>
        {dropdownListArray.length === 0 ? <></> : <> <Row>
            <Col xs={4.5} md={3}>
                <DropDownList icon={dropdownListArray[0].icon} set={setFirstSelect} labelText={dropdownListArray[0].labelText} dropdownItem={firstDropdownItem} defaultValue={firstSelect} width={"100%"}></DropDownList>
            </Col>
            {
                secondDropdownItem != undefined ? <Col xs={4.5} md={3}>
                    <DropDownList icon={dropdownListArray[1].icon} set={setSecondSelect} labelText={dropdownListArray[1].labelText} dropdownItem={secondDropdownItem} defaultValue={secondSelect} width={"100%"}></DropDownList>
                </Col> : <></>
            }
            {
                secondDropdownItem != undefined && thirdDropdownItem != undefined ? <Col xs={4.5} md={3}>
                    <DropDownList icon={dropdownListArray[2].icon} set={setThirdSelect} labelText={dropdownListArray[2].labelText} dropdownItem={thirdDropdownItem} width={"100%"}></DropDownList>
                </Col> : <></>
            }
            <Col xs={4.5} md={3}>
                <Button onClick={handleAdd} style={{ padding: `0`, paddingLeft: `0.2em`, paddingRight: `0.2em`, marginTop: "1.5em", width: "50%", backgroundColor: common.colorCode.no_AA7D24, border: "transparent" }}>
                    <div style={{ display: `flex`, margin: `auto`, textAlign: "center", marginBottom: "0.25em" }}>
                        <div style={{ fontSize: `2em`, margin: `auto`, textAlign: "center", marginRight: `0` }}><strong>{`+`}</strong></div>
                        <div style={{ fontSize: `1.2em`, textAlign: `left`, margin: `auto`, marginTop: `0.7em`, marginLeft: `0` }}><>{`搜尋條件`}</></div>
                    </div>
                </Button>
            </Col>
        </Row>
            <Row>
                <Col>
                    <div style={{ display: horizontal === true ? "inline" : "", color: common.colorCode.no_8E8E8E }}>{`已新增${selectedConditions.length}/${conditionsQuantity}條件`}</div>
                    {selectedConditions.map((selectedCondition, index) => {
                        return <div key={index} style={{ display: horizontal === true ? "inline" : "" }}>
                            <span style={{ backgroundColor: common.colorCode.no_A3432D, color: "white", border: "1px solid black", borderRadius: "5px", marginLeft: "0.4em" }}>
                                <span>{selectedCondition}</span><button id={index} onClick={handleCancel} style={{ backgroundColor: "transparent", border: "transparent", color: "white" }}>{"X"}</button>
                            </span>
                        </div>
                    })}
                    <button onClick={handleClear} style={{ backgroundColor: "transparent", border: "transparent", color: common.colorCode.no_8E8E8E }}>{clearButtonText}</button>
                </Col>
            </Row>
        </>}
    </>)
}