// css
import "./assets/css/applyApp.css";
import "./assets/css/materialOutlinedIcon.css";
import "./assets/css/materialIcon.css";
import "./assets/css/application.css";
import "react-datepicker/dist/react-datepicker.css";

import React from "react";
import { Route, Switch } from "react-router-dom";

import ApplicationLoginPage from "./views/Application/ApplicationLoginPage";
import ApplicationRegisterPage from "./views/Application/ApplicationRegisterPage";
import ApplicationVerifyPage from "./views/Application/ApplicationVerifyPage";
import ApplicationListPage from "./views/Application/ApplicationListPage";
import ApplicationCreatePage from "./views/Application/ApplicationCreatePage";
import ApplicationEditPage from "./views/Application/ApplicationEditPage";
import ApplicationViewPage from "./views/Application/ApplicationViewPage";
import ApplicationDownloadPage from "./views/Application/ApplicationDownloadPage";
import cookieManager from "./utilities/cookiesManager";

function ApplyApp() {
  return (
    <>
      <Switch>
        <Route exact path="/application/login">
          <ApplicationLoginPage></ApplicationLoginPage>
        </Route>
        <Route exact path="/application/register">
          <ApplicationRegisterPage></ApplicationRegisterPage>
        </Route>
        <Route
          exact
          path="/application/verify"
          component={ApplicationVerifyPage}
        ></Route>
        <Route
          exact
          path="/application/verify/:usertoken"
          component={ApplicationVerifyPage}
        ></Route>
        <Route
          exact
          path="/application/list"
          component={ApplicationListPage}
        ></Route>
        <Route
          exact
          path="/application/form/create"
          component={ApplicationCreatePage}
        ></Route>
        <Route
          exact
          path="/application/form/edit/:id"
          component={ApplicationEditPage}
        ></Route>
        <Route
          exact
          path="/application/form/view/:id"
          component={ApplicationViewPage}
        ></Route>
        <Route
          exact
          path="/application/form/download/:id"
          component={ApplicationDownloadPage}
        ></Route>
        <Route path="/application">
          {() => {
            alert("錯誤的網址，請重新登入");
            cookieManager.setCookie("application", 0);
            sessionStorage.clear();
            window.location.href = "/application/login";
          }}
        </Route>
      </Switch>
    </>
  );
}

export default ApplyApp;
