// css
import "../assets/css/spinner.css";
import "../assets/css/topBuffer.css";
import "../assets/css/closeFocusBorder.css";
import "../assets/css/pageLink.css";
import "react-toastify/dist/ReactToastify.min.css";

// img
import "../assets/css/backgroundSearch.css";

// js
import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  Container,
  Pagination,
  Row,
  Col,
  Button,
  Collapse,
} from "react-bootstrap";
import FilterAndSearchUpperCard from "../components/FilterAndSearchUpperCard";
import MessageCard from "../components/MessageCard";
import InstructionsCard from "../components/InstructionsCard";
import GetHumanResourceDataCard from "../features/GetHumanResourceDataCard";
import { toast } from "react-toastify";
import apiCaller from "../utilities/apiCaller";
import LoadingBar from "react-top-loading-bar";

// utilities
import cookieManager from "../utilities/cookiesManager";

// common
import common from "../utilities/common";

// layout
import Layout from "../layout/layout";

// hr context
export const HRContext = React.createContext({});

/**
 * human resource data page
 * @returns {JSX} HumanResourceSearch components
 */
function HumanResourceSearch() {
  console.log("HumanResourceSearch");
  document.body.style.backgroundColor = "#e0c8b3";

  const PREVIOUS = `previous`;
  const NEXT = `next`;
  const LAST_PAGE = `lastpage`;
  const FIRST_PAGE = `firstpage`;

  // hook state
  // data is coming from api , displayData is for pagination display
  // ⚠️ warning : data's last element is message object, remeber to remove it before using
  const [data, setData] = useState([]);
  const [active, setActive] = useState(Number(1));
  const [message, setMessage] = useState(undefined);
  const [progressBarNow, setProgressBarNow] = useState(undefined);
  const [openDescription, setOpenDescription] = useState(true);

  // const for text use
  const displayNumber = 10;
  let pageCount;
  if (message) {
    pageCount = Math.ceil(message.dataCount / displayNumber);
  } else if (sessionStorage.getItem(`message`)) {
    const sessionMessage = JSON.parse(sessionStorage.getItem(`message`));
    pageCount = Math.ceil(sessionMessage.dataCount / displayNumber);
  }

  let displayData = [];
  if (data.length != 0 && !data[0].condition) {
    displayData = getDisplayDataArrayBy(data, displayNumber, 1);
  }

  // hook effect
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("data"));
    const pageSelected = sessionStorage.getItem("active");

    if (data) {
      if (displayNumber && pageSelected) {
        setActive(pageSelected);
      }

      const selectedData = [];
      data.map((x) => {
        if (x.selected) {
          selectedData.push(x);
        }
      });

      sessionStorage.setItem(`selectedData`, JSON.stringify(selectedData));

      setData(data);
    } else {
      // set session first
      sessionStorage.setItem("selectedData", []);
    }
  }, []);

  useEffect(() => {
    if (!data) return;

    // set session storage
    sessionStorage.setItem("data", JSON.stringify(data));
  }, [data]);

  // If sessionStorage's data is not empty array, then set [openDescription] to false
  useEffect(() => {
    let data = JSON.parse(sessionStorage.getItem("data"));
    if (data && data.length !== 0) {
      setOpenDescription(false);
    }
  }, [data]);

  useEffect(() => {
    sessionStorage.setItem("active", active);
    window.scrollTo(0, 400);
  }, [active]);

  // Get page items which is show on pagination
  const pageItems = () => {
    if (Number(data.length) === 0) return;

    let pageItems = [];

    if (Number(active) !== 1) {
      pageItems.push(
        <Pagination.Item
          page={FIRST_PAGE}
          key={"double_arrow_left"}
          onClick={selectPage}
        >
          <span
            page={FIRST_PAGE}
            className="material-icons"
            style={{ transform: `rotate(180deg)` }}
          >{`double_arrow`}</span>
        </Pagination.Item>
      );

      pageItems.push(
        <Pagination.Item
          page={PREVIOUS}
          key={"navigate_before"}
          onClick={selectPage}
        >
          <span
            page={PREVIOUS}
            className="material-icons"
          >{`navigate_before`}</span>
        </Pagination.Item>
      );
    }

    if (Number(active) <= 2) {
      // page is limited to 5
      let count = pageCount;
      if (count > 5) count = 5;

      for (let index = 1; index <= count; index++) {
        pageItems.push(
          <Pagination.Item
            key={index}
            active={index == active}
            onClick={selectPage}
          >
            {index}
          </Pagination.Item>
        );
      }
    } else if (Number(active) > 2 && Number(active) < pageCount - 1) {
      // selected page count 2 pages forward
      let min = active - 2;
      let max = active + 2;
      for (let index = min; index < active; index++) {
        pageItems.push(
          <Pagination.Item key={index} active={false} onClick={selectPage}>
            {index}
          </Pagination.Item>
        );
      }

      // selected page
      pageItems.push(
        <Pagination.Item key={active} active={true} onClick={selectPage}>
          {active}
        </Pagination.Item>
      );

      // count 2 pages afterward
      for (let index = active + 1; index < max + 1; index++) {
        pageItems.push(
          <Pagination.Item key={index} active={false} onClick={selectPage}>
            {index}
          </Pagination.Item>
        );
      }
    } else {
      // page is limited to 5
      let count = pageCount;
      let startNumber = count - 4;
      if (startNumber < 1) startNumber = 1;

      for (let index = startNumber; index <= count; index++) {
        pageItems.push(
          <Pagination.Item
            key={index}
            active={index == active}
            onClick={selectPage}
          >
            {index}
          </Pagination.Item>
        );
      }
    }

    if (Number(active) !== Number(pageCount)) {
      pageItems.push(
        <Pagination.Item page={NEXT} key={"navigate_next"} onClick={selectPage}>
          <span page={NEXT} className={"material-icons"}>
            {"navigate_next"}
          </span>
        </Pagination.Item>
      );

      pageItems.push(
        <Pagination.Item
          page={LAST_PAGE}
          key={"double_arrow"}
          onClick={selectPage}
        >
          <span page={LAST_PAGE} className="material-icons">{`double_arrow`}</span>
        </Pagination.Item>
      );
    }

    return pageItems;
  };

  // pagination function
  const selectPage = async (e) => {
    if (e.target.innerText.includes("current")) return;
    let page = Number(0);

    // choose first page
    if (e.target.getAttribute(`page`) === FIRST_PAGE) {
      if (active == 1) {
        common.toastEmmiter("已經到達第一頁");
        return;
      }

      page = 1;
      setActive(1);
    }
    // choose previous page
    else if (e.target.getAttribute(`page`) === PREVIOUS) {
      if (active == 1) {
        common.toastEmmiter("已經到達第一頁");
        return;
      }

      page = active - 1;
      setActive(Number(page));
    }
    // choose next page
    else if (e.target.getAttribute(`page`) === NEXT) {
      if (active == pageCount) {
        common.toastEmmiter("最後一頁了");
        return;
      }

      page = active + 1;
      setActive(Number(page));
    }
    // choose last page
    else if (e.target.getAttribute(`page`) === LAST_PAGE) {
      if (active == pageCount) {
        common.toastEmmiter("最後一頁了");
        return;
      }

      page = pageCount;
      setActive(pageCount);
    }
    // choose number
    else {
      page = parseInt(e.target.innerText);
      setActive(Number(e.target.innerText));
    }

    try {
      common.loader.show();
      const searchCondition = JSON.parse(
        sessionStorage.getItem("searchCondition")
      );

      const formData = new FormData();
      formData.append(
        "auth_token",
        cookieManager.getCookieValue("humanresource")
      );
      formData.append("language", searchCondition.language);
      formData.append("dialect", searchCondition.dialect);
      if (searchCondition.keyword)
        formData.append("keyword", searchCondition.keyword);
      if (searchCondition.location)
        formData.append("location", JSON.stringify(searchCondition.location));
      if (searchCondition.expert)
        formData.append("expert", JSON.stringify(searchCondition.expert));
      formData.append("page", page);

      const options = {
        method: "POST",
        url: process.env.REACT_APP_URL_GET_SEARCH_RESULT,
        body: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      };

      const response = await apiCaller(options);

      if (!response) {
        throw new Error(
          `request error => response is undefined or empty, response == ${response}`
        );
      }

      // add index, selected and feedback props
      const loginData = JSON.parse(sessionStorage.getItem(`loginData`));
      response.data.map((element, index) => {
        element.index = index;
        element.feedback = {
          id: element.id,
          name: loginData.name,
          email: loginData.email,
          feedback: "",
        };
        element.selected = false;
      });

      // if selected data are in the data array
      // set seleted prop to false
      const selectedData = JSON.parse(sessionStorage.getItem("selectedData"));
      response.data.map((item, index) => {
        const found = selectedData.find((object) => object.id == item.id);
        if (found) {
          response.data[index].selected = found.selected;
        }
      });

      setData([...response.data]);
    } catch (error) {
      console.log(error);
      alert(`${error.message} at HumanResourceSearch.js line 287`);
    } finally {
      common.loader.close();
    }
  };

  // export data and redirect to export table
  const exportSelectedData = async () => {
    let selectedData = JSON.parse(sessionStorage.getItem("selectedData"));
    if (selectedData == undefined) {
      common.toastEmmiter("無選取的資料");
      return;
    }

    // Save data to sessionStorage
    sessionStorage.setItem("active", active);

    window.location.href = `/#/confirmhrdatatable`;
  };

  /**
   * setSelectedToFalse
   * @param {Boolean} setSelected selected or not be selected
   */
  const setDataToBeSelectedOrNot = async (e, setSelected = false) => {
    let isDataSelected = false;
    isDataSelected = isDataSelectedOrNot(data);

    let tempData = [];
    tempData = data.map((item) => {
      if (item.hasOwnProperty(`selected`)) {
        item.selected = setSelected;
        return item;
      }

      return item;
    });

    setData([...tempData]);

    sessionStorage.setItem("selectedData", JSON.stringify([]));

    let message = `已取消選取`;
    if (setSelected) message = `選取全部`;

    toast.info(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: `${common.colorCode.no_7D8E96}`,
      },
    });
  };

  // set all data to be selected
  const setAllDataToBeSelectedAndExport = async (e) => {
    try {
      common.loader.show();

      const searchCondition = JSON.parse(
        sessionStorage.getItem("searchCondition")
      );

      const formData = new FormData();
      formData.append(
        "auth_token",
        cookieManager.getCookieValue("humanresource")
      );
      formData.append("language", searchCondition.language);
      formData.append("dialect", searchCondition.dialect);
      if (searchCondition.keyword)
        formData.append("keyword", searchCondition.keyword);
      if (searchCondition.location)
        formData.append("location", JSON.stringify(searchCondition.location));
      if (searchCondition.expert)
        formData.append("expert", JSON.stringify(searchCondition.expert));

      setProgressBarNow(3);

      // 0:搜尋模式,1:匯出模式(每次給500筆) 未填寫預設0
      formData.append("print_mode", 1);

      const message = JSON.parse(sessionStorage.getItem(`message`));
      let count = Math.ceil(message.dataCount / 500);
      let allData = [];

      for (let pageIndex = 1; pageIndex <= count; pageIndex++) {
        formData.append("page", pageIndex);
        const options = {
          method: "POST",
          url: `https://ihr-api.ilrdf.org.tw/api/v1/get_search_result`,
          body: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        };

        const response = await apiCaller(options);

        if (!response) {
          throw new Error(
            `request error => response is undefined or empty, response == ${response}`
          );
        }

        // add index, selected and feedback props
        // selected = true
        response.data.map((element, index) => {
          element.index = index;
          //element.feedback = { name: loginData.name, email: loginData.email, feedback: "" };
          element.selected = true;
        });

        allData = allData.concat(response.data);

        //setProgressBar(numberFrom, numberTo, message.dataCount);
        setProgressBarNow(
          Math.round((allData.length / message.dataCount) * 100)
        );
      }

      sessionStorage.setItem(`selectedData`, JSON.stringify(allData));
    } catch (error) {
      console.log(error);
      alert(`${error.message} at HumanResourceSearch.js line 398`);
    } finally {
      common.loader.close();
    }

    setTimeout(() => {
      exportSelectedData();
    }, 400);
  };

  // check that does any data are selected
  const isDataSelectedOrNot = (data) => {
    let isDataSelected = false;
    data.forEach((x) => {
      if (x.selected === true) {
        isDataSelected = true;
      }
    });

    return isDataSelected;
  };

  /**
   * @param {Array} data data array
   * @param {Number} displayNumber number you want to display in one page once
   * @param {Number} pageSelected page selected
   * @returns {Array} data array for display
   */
  function getDisplayDataArrayBy(data, displayNumber, pageSelected) {
    let tempArray = [];
    for (
      let index = displayNumber * (pageSelected - 1);
      index < displayNumber * pageSelected;
      index++
    ) {
      if (data[index]) tempArray.push(data[index]);
    }

    return tempArray;
  }

  return (
    <>
      <Layout>
        {/* progress bar */}
        {progressBarNow != undefined ? (
          <LoadingBar
            color="#f11946"
            progress={progressBarNow}
            //onLoaderFinished={() => setProgress(0)}
          />
        ) : (
          <></>
        )}

        {/* filter card and search button*/}
        <FilterAndSearchUpperCard
          setData={setData}
          setMessage={setMessage}
          setActive={setActive}
          openDescription={openDescription}
          setOpenDescription={setOpenDescription}
        ></FilterAndSearchUpperCard>
        <br></br>

        <Container>
          {/* description card */}
          <Collapse in={openDescription}>
            <div id="example-collapse-text">
              <InstructionsCard></InstructionsCard>
            </div>
          </Collapse>

          {/* hr data */}
          <Row>
            <Col md={12} style={{ padding: "0.2em 0.2em 0em 0.2em" }}>
              <MessageCard
                message={JSON.parse(sessionStorage.getItem(`message`))}
              ></MessageCard>
            </Col>
          </Row>
          <>
            <br></br>
            <HRContext.Provider value={{ data: data, setData: setData }}>
              <GetHumanResourceDataCard
                data={displayData}
              ></GetHumanResourceDataCard>
            </HRContext.Provider>
            <Pagination className={"justify-content-center"}>
              {pageItems()}
            </Pagination>
          </>
        </Container>
      </Layout>
    </>
  );
}

export default HumanResourceSearch;
