import logoApplicationLogin from "../../assets/img/logoApplicationLogin.jpg";
import SSLLogo from "../../assets/img/SSLSeal.gif";

// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Spinner,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";
import axios from "axios";

function ApplicationVerifyPage(props) {
  let history = useHistory();

  // hook state
  const [sending, setSending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [countIntervalId, setCountIntervalId] = useState(undefined);

  let userToken = undefined;

  if (props.match && props.match.params) {
    userToken = props.match.params.usertoken;
  }

  const [verified, setVerified] = useState(false);

  useEffect(() => {
    if (userToken) {
      verifyMail(userToken);
    }
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(countIntervalId);
    }
  }, [countdown]);

  async function verifyMail(userToken) {
    try {
      const url = process.env.REACT_APP_Application_Url;

      const response = await axios.get(
        `${url}/user_application/${userToken}/verify_email`
      );

      if (response && response.status === 200) {
        const data = response.data;
        if (data.message.includes("Verified")) {
          setVerified(true);

          setTimeout(() => {
            history.push("/application/login");
          }, 5000);
        }
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  const resendVerifyEmail = async () => {
    try {
      setSending(true);

      const url = process.env.REACT_APP_Application_Url;
      const emailRoute =
        process.env.REACT_APP_Application_Route_Send_Verify_Email;

      const email = sessionStorage.getItem("email");

      const formDataSendVerifyEmail = new FormData();
      formDataSendVerifyEmail.append("email", email);

      const sendEmailResponse = await axios.post(
        `${url}/${emailRoute}`,
        formDataSendVerifyEmail,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (sendEmailResponse && sendEmailResponse.status === 200) {
        common.toastEmmiter("已重新寄送驗證信");
        setCountdown(120);

        let intervalId = setInterval(() => {
          setCountdown((c) => c - 1);
        }, 1000);

        setCountIntervalId(intervalId);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        {userToken !== undefined ? (
          <Card
            className={"loginStyle text-center"}
            style={{
              backgroundColor: common.colorCode.no_E6DED3,
              width: "100%",
              padding: "5rem",
              borderRadius: "0.5rem",
            }}
          >
            <Card.Body
              style={{ color: common.colorCode.no_E75711, fontSize: "2rem" }}
            >
              {verified ? (
                <>
                  <Card.Text>信箱驗證成功</Card.Text>
                  <Card.Text>即將導向登入頁...</Card.Text>
                </>
              ) : (
                <>
                  <Card.Text>信箱驗證中...</Card.Text>
                </>
              )}
            </Card.Body>
          </Card>
        ) : (
          <Card
            className={"loginStyle text-center"}
            style={{
              backgroundColor: common.colorCode.no_E6DED3,
              width: "100%",
              padding: "5rem",
              borderRadius: "0.5rem",
            }}
          >
            <Card.Body
              style={{ color: common.colorCode.no_E75711, fontSize: "2rem" }}
            >
              <Card.Text>
                信箱尚未驗證成功，請前往信箱收取驗證信完成驗證
              </Card.Text>
              <Button
                style={{
                  backgroundColor: common.colorCode.no_E75711,
                  border: "none",
                  borderRadius: "1rem",
                  fontSize: "1.5rem",
                }}
                onClick={resendVerifyEmail}
                disabled={countdown !== 0 || sending}
              >
                {sending && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {countdown === 0 || countdown}
                重發驗證信
              </Button>
            </Card.Body>
          </Card>
        )}
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationVerifyPage;
