var cookieManager = cookieManager || {};

cookieManager.getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

/**
 * set cookie with name value and life time
 * @param {string} name cookie name
 * @param {string} value cookie value
 * @param {number} expireTime s
 */
cookieManager.setCookie = async (name, value, expireTime = 0) => {
    const expires = (new Date(Date.now() + expireTime * 1000)).toUTCString();
    document.cookie = `${name}=${value}; expires=` + expires + ";path=/;"
}

export default cookieManager;