import logoApplicationLogin from "../../assets/img/logoApplicationLogin.jpg";
import SSLLogo from "../../assets/img/SSLSeal.gif";

// js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
} from "react-bootstrap";
import FooterComponent from "../../components/FooterComponent";
import common from "../../utilities/common";
import apiCaller from "../../utilities/apiCaller";
import cookieManager from "../../utilities/cookiesManager";
import { toast } from "react-toastify";

// custom css
import "../../assets/css/backgroundLogin.css";
import "../../assets/sass/loginStyle.scss";
import axios from "axios";
//import axios from "../mock/mockAxios";

function ApplicationLoginPage() {
  //#region arrange string var
  const description1 = `’aveoveoyʉ,`;
  const description2 = `歡迎使用`;
  const description3 = `「財團法人原住民族語言研究發展基金會原住民族語言人才資料庫」`;
  const description4 = `本資料庫依據原住民族語言發展法第13條第2項設立，目前僅開放中央及地方各機關單位、各族語言推動組織使用。`;

  const description4_1 = `原住民族語言人才資料庫使用規則`;
  const description4_2 = `1.僅限於業務必要之範圍使用，不可另做他用。`;
  const description4_3 = `2.應以善良管理人之注意妥為保管及確保資料之安全，並請妥善保管帳號。`;
  const description4_4 = `3.族語人才資料之使用，應遵守個人資料保護法，如有違反應負全責。`;
  const description4_5 = `4.申請資料個人資料僅供本基金會建檔及行必要之聯繫，不另做他用。`;

  const description5 = `本資料庫帳號密碼請洽本基金會申請，並請務必遵守個資法及相關規範妥善保管。`;
  const descriptionColor = `#5A493F`;
  const descriptionColor2 = `#E75711`;

  const contact1 = `若有其他問題請洽本基金會研究發展組業務承辦人：`;
  const contact2 = `徐小姐`;
  const contactBackgroundColor = `#F6F6F6`;
  const contactColor = `#7D8E96`;
  const phone = `02-23418508#602`;
  const or = `或`;
  const email = `akiwhsu@ilrdf.org.tw`;

  const copyRight1 = `財團法人原住民族語言研究發展基金會`;
  const copyRight2 = `Indigenous Languages Research and Development Foundation`;
  const copyRight3 = `10093台北市中正區羅斯福路一段63號 電話：02-23418508#602`;
  const copyRight4 = `財團法人原住民族語言研究發展基金會 ©版權所有`;
  const copyRight5 = `Copyright © ${new Date().getFullYear()} Indigenous Languages Research and Development Foundation`;

  const warningMessageColor = `#A3432D`;
  const buttonColor = "#E75711";
  const footerColor = "#7D8E96";
  //#endregion

  // hook state
  const [warningMessage, setWarningMessage] = useState("");
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  let history = useHistory();

  const handleChange = (e) => {
    switch (e.target.id) {
      case "account":
        setAccount(e.target.value);
        return;
      case "password":
        setPassword(e.target.value);
        return;
      default:
        return;
    }
  };

  const handleLogin = async (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    const type = e.type;
    if (keycode === 13 || type === "click") {
      try {
        const formData = new FormData();
        formData.append("email", account);
        formData.append("password", password);

        try {
          const url = process.env.REACT_APP_Application_Url;
          const routeLogin = process.env.REACT_APP_Application_Route_Login;

          const response = await axios.post(`${url}/${routeLogin}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.status === 200) {
            const data = response.data;
            if (data) {
              await sessionStorage.setItem("email", account);

              if (isEmailVerified(data.email_verify)) {
                const userToken = data.user_token;

                await cookieManager.setCookie("application", userToken, 1800);

                history.push("/application/list");
              } else {
                history.push("/application/verify");
              }
            }
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data.errors &&
            error.response.data.errors.password
          ) {
            toast.error("密碼有誤", { position: "top-center" });
          } else if (
            error.response &&
            error.response.data.errors &&
            error.response.data.errors.email
          ) {
            const message = error.response.data.errors.email[0];
            toast.error(message, { position: "top-center" });
          } else {
            console.error(error);
            alert(error.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isEmailVerified = (email_verify) => {
    if (parseInt(email_verify) === 0) {
      return false;
    }

    return true;
  };

  const onClickRegisterButton = () => {
    history.push("/application/register");
  };

  function openUrl(e) {
    const url =
      "https://publicca.hinet.net/SSLQueryCert/SSLQueryCert.jsp?Domain_name=ihr.ilrdf.org.tw";
    window.open(url);
  }

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img
              src={`${logoApplicationLogin}`}
              width={`100%`}
              height={`auto`}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>
      <Container className={"justify-content-md-center"}>
        <Card
          style={{
            color: descriptionColor,
            backgroundColor: "#E6DED3",
            border: "none",
          }}
        >
          <Card.Body>
            <Card.Text className={"text-center"}>{description1}</Card.Text>
            <Card.Text className={"text-center"}>
              {description2}
              <span style={{ color: descriptionColor2 }}>{description3}</span>
            </Card.Text>
            <Card.Text className={"text-center"}>{description4}</Card.Text>
            <Card.Text className={"text-center"}>{description4_1}</Card.Text>
            <Card.Text className={"text-center"}>{description4_2}</Card.Text>
            <Card.Text className={"text-center"}>{description4_3}</Card.Text>
            <Card.Text className={"text-center"}>{description4_4}</Card.Text>
            <Card.Text className={"text-center"}>{description4_5}</Card.Text>
            <Card.Text className={"text-center"}>{description5}</Card.Text>
            <Card.Text
              as={"div"}
              style={{
                backgroundColor: contactBackgroundColor,
                border: "5px solid white",
              }}
              className={"text-center"}
            >
              <h6 style={{ marginTop: "0.625em" }}>{contact1}</h6>
              <h6 style={{ color: contactColor, marginBottom: "0.625em" }}>
                {contact2}
                {` `}
                <span className="material-icons">call</span>
                {` `}
                {phone}
                {` `}
                {or}
                {` `}
                <span className="material-icons">mail</span>
                {` `}
                {email}
              </h6>
            </Card.Text>
          </Card.Body>
        </Card>
        <br></br>
        <Card className={"loginStyle text-center"}>
          <Card.Body>
            <div
              style={{
                float: "right",
                textAlign: "right",
                width: "100%",
                margin: "0 0 0.5rem 0",
              }}
            >
              <span
                style={{
                  display: "inline",
                  width: "30%",
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                第一次使用請點此
              </span>{" "}
              <Button
                style={{
                  borderRadius: "1rem",
                  width: "15%",
                  fontSize: "0.1rem",
                  backgroundColor: common.colorCode.no_C18A13,
                  border: "none",
                }}
                onClick={onClickRegisterButton}
              >
                註冊
              </Button>
            </div>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons-outlined">email</span>
                &nbsp;&nbsp;
                {"信箱"}
              </InputGroup.Text>
              <FormControl
                onChange={handleChange}
                id={"account"}
                placeholder="請輸入信箱"
                aria-describedby="username"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{
                  color: descriptionColor,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <span className="material-icons-outlined">lock</span>
                &nbsp;&nbsp;
                {"密碼"}
              </InputGroup.Text>
              <FormControl
                onChange={handleChange}
                onKeyUp={handleLogin}
                id={"password"}
                placeholder="請輸入密碼"
                aria-describedby="password"
                style={{ backgroundColor: "#DFDCDA", border: "none" }}
                type={`password`}
              />
            </InputGroup>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={handleLogin}
                style={{
                  width: "30%",
                  borderRadius: "0.8rem",
                  backgroundColor: buttonColor,
                  border: "none",
                }}
              >
                {"登入"}
              </Button>
            </div>
          </Card.Body>
        </Card>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <FooterComponent></FooterComponent>
        <br></br>
      </Container>
    </>
  );
}

export default ApplicationLoginPage;
