// custom css
import "../assets/css/backgroundLogin.css";

import logo from "../assets/img/logo.jpg";
import logoLogin from "../assets/img/logoLogin.jpg";
import SSLLogo from "../assets/img/SSLSeal.gif";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Spinner,
  InputGroup,
  Button,
  Card,
  Navbar,
  Container,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import FooterComponent from "../components/FooterComponent";
import common from "../utilities/common";
import apiCaller from "../utilities/apiCaller";
import cookieManager from "../utilities/cookiesManager";
import axios from "axios";

function ChangePasswordPage() {
  document.body.style.backgroundColor = "#ffffff";

  //#region arrange string var
  const descriptionColor = `#5A493F`;
  const buttonColor = "#E75711";
  //#endregion

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [sending, setSending] = useState(false);

  const changePassword = async (e) => {
    if (e.keyCode !== 13 && e.type !== "click") {
      return;
    }
    if (!password || !oldPassword || !passwordCheck) {
      common.toastEmmiter("請填寫密碼");
      return;
    }
    if (password !== passwordCheck) {
      common.toastEmmiter("新密碼必須相同");
      return;
    }

    const matchResult = password.match(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/g
    );

    if (!matchResult) {
      common.toastEmmiter("密碼不符規則");
      return;
    }

    try {
      setSending(true);

      const apiUrl = process.env.REACT_APP_URL;
      const routeChangePassword = process.env.REACT_APP_URL_CHANGE_PASSWORD;

      const authToken = cookieManager.getCookieValue("humanresource");

      const formData = new FormData();
      formData.append("password", oldPassword);
      formData.append("new_password", password);
      formData.append("auth_token", authToken);

      const response = await axios.post(
        `${apiUrl}/${routeChangePassword}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response && response.status === 200) {
        common.toastEmmiter("變更成功");

        setTimeout(() => {
          cookieManager.setCookie(`humanresource`, ``);
          sessionStorage.clear();
          window.location.href = `/`;
        }, 5000);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setSending(false);
    }
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const inputValue = e.target.value;

      if (e.target.id === "oldPassword") {
        setOldPassword(inputValue);
      } else if (e.target.id === "password") {
        setPassword(inputValue);
      } else if (e.target.id === "passwordCheck") {
        setPasswordCheck(inputValue);
      }
    }
  };

  return (
    <>
      <Navbar style={{ backgroundColor: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <img src={`${logo}`} width={`100%`} height={`auto`} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br></br>

      <div style={{ margin: "auto", textAlign: "center", width: "50%" }}>
        <Row>
          <Col style={{ textAlign: "left" }}>
            <span className="material-icons-outlined">lock</span>
            &nbsp;&nbsp;
            {"密碼"}
          </Col>
          <Col md={10}>
            <Form.Control
              onChange={handleChange}
              id={"oldPassword"}
              placeholder="請輸入原密碼"
              aria-describedby="password"
              style={{ backgroundColor: "#DFDCDA", border: "none" }}
              type={`password`}
            />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col style={{ textAlign: "left" }}>
            <span className="material-icons-outlined">lock</span>
            &nbsp;&nbsp;
            {"新密碼"}
          </Col>
          <Col md={10}>
            <Form.Control
              onChange={handleChange}
              id={"password"}
              placeholder="請輸入新密碼"
              aria-describedby="password"
              style={{ backgroundColor: "#DFDCDA", border: "none" }}
              type={`password`}
            />
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col></Col>
          <Col md={10}>
            <Form.Control
              onChange={handleChange}
              onKeyUp={changePassword}
              id={"passwordCheck"}
              placeholder="請再輸入一次新密碼"
              aria-describedby="password"
              style={{ backgroundColor: "#DFDCDA", border: "none" }}
              type={`password`}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              密碼組合請符合下列規則
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              1.一個大寫英文字母
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              2.一個小寫英文字母
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              3.一個數字
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              4.一個特殊字元，如： ! @ # $ % & 等
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={2}> </Col>
          <Col md={10} style={{ textAlign: "left" }}>
            <span style={{ color: common.colorCode.no_E75711 }}>
              5.密碼至少需要8碼(含)以上
            </span>
          </Col>
        </Row>
        <br></br>
        <div style={{ textAlign: "center" }}>
          <Button
            onClick={changePassword}
            style={{
              width: "30%",
              borderRadius: "0.8rem",
              backgroundColor: buttonColor,
              border: "none",
            }}
            disabled={sending}
          >
            {sending && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {"變更密碼"}
          </Button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <FooterComponent></FooterComponent>
      <br></br>
    </>
  );
}

export default ChangePasswordPage;
