import React from "react";
import HumanResourceDataCard from "../components/HumanResourceDataCard";

/**
 * Get HumanResource Data Card
 * @param {Array} data data array for displaying data card
 * @returns HumanResourceDataCard array
 */
export default function GetHumanResourceDataCard({ data }) {
    return (
        <>
            {
                data.map((element, index) => {
                    return <div key={index} > <HumanResourceDataCard data={element} key={index}></HumanResourceDataCard><br></br></div>
                })
            }
        </>
    )
}