import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Row, Col, Form } from "react-bootstrap";

import UploadFileButtonComponent from "../components/UploadFileButtonComponent";
import ProgressBarComponent from "../components/ProgressBarComponent";
import common from "../utilities/common";
import axios from "axios";
import cookieManager from "../utilities/cookiesManager";

/**
 * 申請單元件
 * @param {string} state 顯示狀態狀態碼
 * 0: 申請單建立尚未鎖定
 * 1: 等待上傳用印PDF
 * 2: 審核中
 * 3: 初審通過
 * 4: 審核通過
 * 5: 審核不通過
 */
function ApplicationTableComponent({
  id,
  applicationNumber,
  accountCustodian,
  state,
  filePath,
  updatedTime,
  refreshParent,
}) {
  let stateDescription;
  if (state === "0") {
    stateDescription = "申請單建立尚未鎖定";
  } else if (state === "1") {
    stateDescription = "等待上傳用印PDF";
  } else if (state === "2") {
    stateDescription = "審核中";
  } else if (state === "3") {
    stateDescription = "初審通過";
  } else if (state === "4") {
    stateDescription = "審核通過";
  } else if (state === "5") {
    stateDescription = "審核不通過";
  }

  const editButtonStyle = {
    margin: "auto",
    backgroundColor: common.colorCode.no_E75711,
    border: "none",
    width: "10vw",
    borderRadius: "1rem",
    fontSize: "1.2rem",
  };

  let history = useHistory();

  const [pdfLink, setPdfLink] = useState(filePath);

  const [isPdfUploaded, setIsPdfUploaded] = useState(() => {
    if (state === "1") {
      if (filePath) {
        return true;
      }
    }

    return false;
  });

  const uploadPdfButtonOnClick = async (e) => {
    if (!e.target.files[0]) {
      common.toastEmmiter("沒有選到");
      return;
    }

    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeUploadPdf =
        process.env.REACT_APP_Application_Route_Form_UploadPdf;

      const formData = new FormData();
      formData.append(
        "user_token",
        await cookieManager.getCookieValue("application")
      );
      formData.append("id", id);
      formData.append("file", e.target.files[0]);

      const response = await axios.post(`${url}/${routeUploadPdf}`, formData, {
        headers: { "Content-type": "multipart/form-data" },
      });

      if (response.status === 200) {
        console.log(response);
        const data = response.data;
        if (data && data.id === id && data.file_path) {
          setIsPdfUploaded(true);
          setPdfLink(data.file_path);
          common.toastEmmiter("上傳用印PDF成功");
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.error(error);
      }

      alert(error);
    }
  };

  const lockPdfButtonOnClick = async () => {
    try {
      const url = process.env.REACT_APP_Application_Url;
      const routeLockPdf = process.env.REACT_APP_Application_Route_Form_LockPdf;
      const formData = new FormData();
      formData.append(
        "user_token",
        await cookieManager.getCookieValue("application")
      );
      formData.append("id", id);

      const response = await axios.post(`${url}/${routeLockPdf}`, formData, {
        headers: { "Content-type": "multipart/form-data" },
      });

      if (response.status === 200) {
        common.toastEmmiter("鎖定用印PDF成功");
        refreshParent();
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response);
      } else {
        console.error(error);
      }

      alert(error);
    }
  };

  const goToPageByState = () => {
    if (state === "0") {
      history.push(`/application/form/edit/${id}`);
    } else {
      history.push(`/application/form/view/${id}`);
    }
  };

  const goToDownloadPage = () => {
    window.open(`/application/form/download/${id}`);
  };

  const openFilePath = () => {
    window.open(`${filePath}`);
  };

  const openPdfLink = () => {
    console.log(pdfLink);
    window.open(pdfLink);
  };

  return (
    <>
      <Table
        style={{
          fontSize: "1.2rem",
          border: "0.38rem solid #828282",
          padding: "0",
          marginBottom: "0.9rem",
        }}
      >
        <tbody>
          <tr
            style={{
              color: "white",
              fontSize: "1.3rem",
              backgroundColor: common.colorCode.no_E75711,
            }}
          >
            <td>單號</td>
            <td>帳號保管人</td>
            <td>狀態</td>
            <td>申請單下載</td>
            <td>用印文件上傳</td>
            <td></td>
            <td
              rowSpan={2}
              style={{
                textAlign: "center",
                borderLeft: "0.38rem solid #828282",
                backgroundColor: "white",
                verticalAlign: "middle",
                width: "20%",
              }}
            >
              <Button
                style={
                  state === "0"
                    ? editButtonStyle
                    : {
                        ...editButtonStyle,
                        backgroundColor: common.colorCode.no_7D8E96,
                      }
                }
                onClick={goToPageByState}
              >
                {state === "0" ? "編輯" : "檢視"}
              </Button>
            </td>
          </tr>
          <tr style={{ borderBottom: "0.2rem solid #828282" }}>
            <td>{applicationNumber}</td>
            <td>{accountCustodian}</td>
            <td style={{ color: state === "5" ? "red" : "" }}>
              {stateDescription}
            </td>
            <td>
              {state === "0" ? (
                "尚未鎖定"
              ) : (
                <Button
                  style={{
                    fontSize: "1rem",
                    backgroundColor: common.colorCode.no_C18A13,
                    border: "none",
                    borderRadius: "1rem",
                  }}
                  onClick={goToDownloadPage}
                >
                  下載申請單
                </Button>
              )}
            </td>
            <td>
              {state === "0" && "尚未鎖定"}
              {state === "1" ? (
                <>
                  {isPdfUploaded ? (
                    <Button
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        backgroundColor: common.colorCode.no_C18A13,
                        border: "none",
                        borderRadius: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={openPdfLink}
                    >
                      檢視上傳文件
                    </Button>
                  ) : (
                    <UploadFileButtonComponent
                      key={id}
                      id={id}
                      buttonStyle={{
                        fontSize: "1rem",
                        color: "white",
                        backgroundColor: common.colorCode.no_C18A13,
                        border: "none",
                        borderRadius: "1rem",
                      }}
                      text={isPdfUploaded ? "檢視上傳文件" : "上傳用印PDF"}
                      accept={"application/pdf"}
                      onClick={uploadPdfButtonOnClick}
                    ></UploadFileButtonComponent>
                  )}
                </>
              ) : (
                <></>
              )}
              {state !== "0" && state !== "1" && (
                <Button
                  style={{
                    fontSize: "1rem",
                    backgroundColor: common.colorCode.no_C18A13,
                    border: "none",
                    borderRadius: "1rem",
                  }}
                  onClick={openFilePath}
                >
                  已上傳 檢視
                </Button>
              )}
            </td>
            <td style={{ width: "20vw" }}>
              {isPdfUploaded && (
                <>
                  <Button
                    style={{
                      fontSize: "1rem",
                      backgroundColor: common.colorCode.no_7D8E96,
                      border: "none",
                      borderRadius: "1rem",
                    }}
                    onClick={() => {
                      setIsPdfUploaded(false);
                    }}
                  >
                    取消
                  </Button>
                  &nbsp;
                  <Button
                    style={{
                      fontSize: "1rem",
                      backgroundColor: common.colorCode.no_C18A13,
                      border: "none",
                      borderRadius: "1rem",
                    }}
                    onClick={lockPdfButtonOnClick}
                  >
                    確定上傳
                  </Button>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td colSpan={7}>
              <Row style={{ padding: " 0 1rem 0 1rem" }}>
                <Col md={2}>
                  <div>申請進度</div>
                  <div style={{ fontSize: "0.7rem" }}>
                    <div>更新時間</div>
                    <span
                      style={{ display: "inline-block", whiteSpace: "nowrap" }}
                    >
                      {updatedTime}
                    </span>
                  </div>
                </Col>
                <Col md={10}>
                  <ProgressBarComponent progress={state}></ProgressBarComponent>
                  <Row style={{ fontSize: "0.7rem" }}>
                    <Col style={{ padding: "0" }}>申請單建立尚未鎖定</Col>
                    <Col style={{ padding: "0" }}></Col>
                    <Col style={{ padding: "0" }}>
                      申請單已鎖定 等待上傳用印PDF
                    </Col>
                    <Col style={{ padding: "0" }}></Col>
                    <Col style={{ padding: "0" }}>審核中</Col>
                    <Col style={{ padding: "0" }}></Col>
                    <Col style={{ padding: "0" }}>
                      {state !== "5" ? "初審通過" : ""}
                    </Col>
                    <Col style={{ padding: "0" }}></Col>
                    <Col style={{ padding: "0" }}>
                      {state !== "5" ? "審核通過" : "審核不通過"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </td>
          </tr>
          {state === "5" && (
            <tr style={{ borderBottom: "0.2rem solid #828282" }}>
              <td colSpan={2}>未通過原因</td>
              <td colSpan={5} style={{ fontSize: "1rem" }}>
                資料不齊全，請填寫正確的機關單位與信箱
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ApplicationTableComponent;
