import SSLLogo from "../assets/img/SSLSeal.gif";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Navbar, Container, Table } from "react-bootstrap";

function FooterComponent() {
  const copyRight1 = `財團法人原住民族語言研究發展基金會`;
  const copyRight2 = `Indigenous Languages Research and Development Foundation`;
  const copyRight3 = `10093台北市中正區羅斯福路一段63號 電話：02-23418508#602`;
  const copyRight4 = `財團法人原住民族語言研究發展基金會 ©版權所有`;
  const copyRight5 = `Copyright © ${new Date().getFullYear()} Indigenous Languages Research and Development Foundation`;

  const footerColor = "#7D8E96";

  function openUrl(e) {
    const url =
      "https://publicca.hinet.net/SSLQueryCert/SSLQueryCert.jsp?Domain_name=ihr.ilrdf.org.tw";
    window.open(url);
  }

  return (
    <footer style={{ padding: "2rem" }}>
      <Card
        className={"text-center"}
        style={{
          color: footerColor,
          fontSize: "0.8em",
          backgroundColor: "transparent",
          border: "none",
          marginTop: "auto",
        }}
      >
        <Card.Body>
          <div>{copyRight1}</div>
          <div>{copyRight2}</div>
          <div>{copyRight3}</div>
          <br></br>
          <div>{copyRight4}</div>
          <div>{copyRight5}</div>
          <br></br>
          <a href="#" onClick={openUrl}>
            <img src={SSLLogo} width="50" height="70" />
          </a>
        </Card.Body>
      </Card>
    </footer>
  );
}

export default FooterComponent;
